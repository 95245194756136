<template>

  <el-button @click="$refs.joinSetting.call(this.element,this.data)" icon="Crop"  link type="primary" size="small">连接设置
  </el-button>



  <el-table :data="element.attrs.selects"  style="width: 100%;margin-top: 20px;background: white;--el-table-tr-bg-color:white;--el-table-header-bg-color:white"
            :header-cell-style="{color:'black'}" border size="small">
    <el-table-column label="字段名">
      <template #default="scope">
        {{ scope.row.alias ? scope.row.alias : scope.row.name }}
      </template>
    </el-table-column>
    <el-table-column label="节点">
      <template #default="scope">
        {{ data.nodeList.find(find => find.id === scope.row.id).name }}
      </template>
    </el-table-column>

  </el-table>

  <join-setting ref="joinSetting"></join-setting>
</template>

<script>
import joinSetting from "@/components/etl/node/join/joinSetting.vue";

export default {
  components: {
    joinSetting
  },
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    }
  },


}

</script>


<style scoped lang="scss">

</style>