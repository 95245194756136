<template>

  <el-dialog v-model="dialogVisible" title="分组" :close-on-click-modal="false" width="55%" draggable
             style="border-radius: 10px"
             append-to-body>
    <el-table :data="tableData" style="width: 100%;" size="small" stripe :height="isFullscreen?'70vh':'55vh'" border :header-cell-style="{color:'#181A1D'}">
      <el-table-column label="字段" prop="column" align="center"></el-table-column>
      <el-table-column label="输入源" align="center">
        <template #default="scope">
          {{ scope.row.nodeName }} ({{ scope.row.nodeType }})
        </template>
      </el-table-column>
      <el-table-column label="维度" align="center" width="80">
        <template #default="scope">
         <el-checkbox :model-value="isOwned(scope.row.column,'dimensions')"
                       @change="handleClick(scope.row.column,'dimensions')"
                       :disabled="isDimensionDisabled(scope.row.column)"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="分组顺序" align="center" width="80">
        <template #default="scope">
          {{ getSortNumber(scope.row.column) }}
        </template>
      </el-table-column>


      <el-table-column label="合计值" align="center" width="80">
        <template #default="scope">
         <el-checkbox :model-value="isOwned(scope.row.column,'summary')"
                       @change="handleClick(scope.row.column,'summary')"
                       :disabled="isCountDisabled(scope.row.column)"></el-checkbox>
        </template>
      </el-table-column>

      <el-table-column label="列合并" align="center" width="80">
        <template #default="scope">
         <el-checkbox :model-value="isOwned(scope.row.column,'concat_columns')"
                       @change="handleClick(scope.row.column,'concat_columns')"
                       :disabled="isCountDisabled(scope.row.column)"></el-checkbox>
        </template>
      </el-table-column>


      <el-table-column label="显示值" align="center" width="80">
        <template #default="scope">
         <el-checkbox :model-value="isOwned(scope.row.column,'others')" @change="handleClick(scope.row.column,'others')"
                       :disabled="isCountDisabled(scope.row.column)"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="平均值" align="center" width="80">
        <template #default="scope">
          <el-checkbox :model-value="isOwned(scope.row.column,'avg_columns')"
                       @change="handleClick(scope.row.column,'avg_columns')"
                       :disabled="isCountDisabled(scope.row.column)"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="最大值" align="center" width="80">
        <template #default="scope">
         <el-checkbox :model-value="isOwned(scope.row.column,'max_columns')"
                       @change="handleClick(scope.row.column,'max_columns')"
                       :disabled="isCountDisabled(scope.row.column)"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="最小值" align="center" width="80">
        <template #default="scope">
         <el-checkbox :model-value="isOwned(scope.row.column,'min_columns')"
                       @change="handleClick(scope.row.column,'min_columns')"
                       :disabled="isCountDisabled(scope.row.column)"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="计数" align="center" width="70">
        <template #default="scope">
         <el-checkbox :model-value="isOwned(scope.row.column,'count_columns')"
                       @change="handleClick(scope.row.column,'count_columns')"
                       :disabled="isCountDisabled(scope.row.column)"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="排序" align="center" width="70">
        <template #default="scope">
         <el-checkbox :model-value="isOwned(scope.row.column,'dim_orders')"
                       @change="handleClick(scope.row.column,'dim_orders')"></el-checkbox>
        </template>
      </el-table-column>
    </el-table>
    <error ref="error"></error>
  </el-dialog>

</template>

<script>
import {mapStores} from "pinia";
import {useEtlStore} from "@/stores/etl";
import error from '@/components/etl/node/error.vue'

export default {
  components: {
    error
  },
  computed: {
    ...mapStores(useEtlStore)
  },
  props: {
    isFullscreen: {
      type: Boolean,
      default: () => false,
    }
  },
  data() {
    return {
      node: null,
      data: null,
      columns: [],
      tableData: [],
      dialogVisible:false
    }
  },
  methods: {
    call(node, data) {
      this.node = node;
      this.data = data;

      this.dialogVisible = true;

      let inputs = this.data.lineList.filter(filter => filter.to === this.node.id);
      if (inputs.length !== 1) {
        this.$nextTick(() => this.$refs.error.show('输入源未设置，组件无法正常运行'))
      } else {
        this.$nextTick(() => this.$refs.error.close())
        this.initColumns(inputs[0].from, this.data);
      }


    },
    initColumns(id, data) {
      this.etlStore.getNodeColumns(id, data).then(res => {
        if (res.code === 200) {
          this.columns = res.data;
          this.tableData = this.columns.map(mapper => {
            return {
              column: mapper.name,
              nodeName: mapper.nodeName,
              nodeType: mapper.nodeType,
            }
          })
        }
      })
    },
    isOwned(column, attributeName) {

      if (!this.node.attrs[attributeName])
        this.node.attrs[attributeName] = [];


      return this.node.attrs[attributeName].includes(column);
    },
    handleClick(column, attributeName) {

      if (!this.node.attrs[attributeName])
        this.node.attrs[attributeName] = [];

      let index = this.node.attrs[attributeName].indexOf(column);

      if (index === -1) {
        this.node.attrs[attributeName].push(column)
      } else {
        this.node.attrs[attributeName].splice(index, 1)
      }
    },
    getSortNumber(column) {
      let index = this.node.attrs.dimensions.indexOf(column);
      return index === -1 ? "" : index + 1;
    },
    isDimensionDisabled(column) {
      let array = ['summary', 'max_columns', 'min_columns', 'avg_columns', 'count_columns', 'concat_columns', 'others']

      for (let attr of array) {
        if (!this.node.attrs[attr])
          this.node.attrs[attr] = [];

        if (this.node.attrs[attr].indexOf(column) !== -1)
          return true;
      }

      return false;
    },
    isCountDisabled(column) {
      return this.node.attrs.dimensions.indexOf(column) !== -1;
    }

  }

}
</script>

<style scoped>

:deep( .el-table thead) {
  color: #1F1C2C;
}
</style>