<template>

	<div v-if="visible" class="layout">
		<img :src="error" width="20%" alt=""/>
		<el-input class="layout-code" :value="message" type="textarea" :autosize="{ minRows: 4, maxRows: 8}"></el-input><br>
	</div>

</template>

<script>
	export default {
		data() {
			return {
				visible: false,
				title: '操作失败',
				message: '',
				error: require('@/assets/error.png'),
			}
		},
		methods: {
			show(message) {
				this.visible = true
				if (message) this.message = message
			},
			close() {
				this.visible = false
			}
		}
	}
</script>

<style scoped lang="scss">
	.layout {
		text-align: center;
		//border: 1px #cccccc dashed;
		border-radius: 8px;
		margin-top: 20px;
		width: 100%;
	}
	
	.layout-code{
		width: 66%;
		margin-bottom: 40px;
	}
	
</style>
