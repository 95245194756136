import {createRouter, createWebHistory} from 'vue-router'


const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import( '../views/indexView.vue'),
    },
    {
        path: '/test',
        name: 'test',
        component: () => import( '../views/test.vue'),
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import( '../views/loginView.vue'),
        meta: {
            title: '登录 | Nocode'
        },
    },
    {
        path: '/explorer',
        name: 'explorer',
        component: () => import('../views/explorer-layout.vue'),
        children: [
            {
                path: 'workbench',
                component: () => import('../views/explorer-workbench.vue'),
                meta: {
                    title: '工作台 | 资源 | Nocode'
                },
            },
            {
                path: 'app',
                component: () => import('../views/explorer-app.vue'),
                meta: {
                    title: '应用 | 资源 | Nocode'
                },
            }, {
                path: 'dept',
                component: () => import('../views/explorer-dept.vue'),
                meta: {
                    title: '部门 | 资源 | Nocode'
                },
            }, {
                path: 'user',
                component: () => import('../views/explorer-user.vue'),
                meta: {
                    title: '用户 | 资源 | Nocode'
                },
            }, {
                path: 'role',
                component: () => import('../views/explorer-role.vue'),
                meta: {
                    title: '角色 | 资源 | Nocode'
                },
            },
            {
                path: 'image',
                component: () => import('../views/explorer-image.vue'),
                meta: {
                    title: '图片 | Nocode'
                },
            },
            {
                path: 'attendance',
                component: () => import('../views/explorer-attendance.vue'),
                meta: {
                    title: '考勤打卡 | Nocode'
                },
            },
            {
                path: 'calendar',
                component: () => import('../views/explorer-calendar.vue'),
                meta: {
                    title: '日程 | Nocode'
                },
            },
           ]
    },
    {
        path: '/app',
        name: 'app',
        component: () => import('../views/app-layout.vue'),
        meta: {
            title: 'APP | Nocode'
        },
        children: [
            {
                path: 'home/:application_id',
                component: () => import('../views/app-home.vue'),
                meta: {
                    title: '首页 | 应用 | Nocode'
                },
            },
            {
                path: 'form/:application_id/:form_id',
                component: () => import('../views/app-form.vue'),
                meta: {
                    title: '表单 | 应用 | Nocode'
                },
            },
            {
                path: 'link/:application_id/:id',
                component: () => import('../views/app-link.vue'),
                meta: {
                    title: '外部链接 | 应用 | Nocode'
                },
            },
            {
                path: 'document/:application_id/:id',
                component: () => import('../views/app-document.vue'),
                meta: {
                    title: '自定义页面 | 应用 | Nocode'
                },
            },
            {
                path: 'report/:application_id/:id',
                component: () => import('../views/app-report.vue'),
                meta: {
                    title: '报表 | 应用 | Nocode'
                },
            },
        ]
    },
    {
        path: '/print/:form_id/:data_id',
        component: () => import('../views/app-print.vue'),
        meta: {
            title: '打印 | 应用 | Nocode'
        },
    },
    {
        path: '/print-template/:form_id/:data_id/:template_index',
        component: () => import('../views/app-print-template.vue'),
        meta: {
            title: '打印 | 应用 | Nocode'
        },
    },
    {
        path: '/design-document/:application_id/:id',
        name: 'design document',
        component: () => import( '../views/design-document.vue'),
        meta: {
            title: '自定义页面 | Design | Nocode'
        },
    },
    {
        path: '/etl',
        name: 'ETL',
        component: () => import( '../views/etl.vue'),
        meta: {
            title: 'ETL | Design | Nocode'
        }
    },
    {
        path: '/design',
        name: 'design',
        component: () => import( '../views/design-form-layout.vue'),
        children: [
            {
                path: 'form/:application_id/:form_id',
                component: () => import( '../views/design-form.vue'),
                meta: {
                    title: '表单 | Design | Nocode'
                },
            },
            {
                path: 'form/list/:application_id/:form_id',
                component: () => import( '../views/design-form-list.vue'),
                meta: {
                    title: '表单列表 | Design | Nocode'
                },
            },
            {
                path: 'form/flow/:application_id/:form_id',
                component: () => import( '../views/design-form-flow.vue'),
                meta: {
                    title: '流程 | Design | Nocode'
                },
            },
            {
                path: 'form/setting/:application_id/:form_id',
                component: () => import( '../views/design-form-setting.vue'),
                meta: {
                    title: '表单设置 | Design | Nocode'
                },
            }
        ]
    }]


const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.title) {
        document.title = to.meta.title;
    }
    next();
});

export default router
