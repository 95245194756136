<template>

  <el-button @click="$refs.squashSetting.call(element,data)" icon="Filter" link type="primary" size="small">条件设置
  </el-button>


  <el-form label-width="80px" label-position="top" style="margin-top: 20px;--el-text-color-regular:black" size="small" >
    <el-form-item label="分组/去重" v-if="element.attrs.dimensions.length>0">
      <template #default>
        <el-tag v-for="(item,index) in element.attrs.dimensions" effect="plain" :key="index">{{item}}</el-tag>
      </template>
    </el-form-item>

    <el-form-item label="合计值" v-if="element.attrs.summary.length>0">
      <template #default>
        <el-tag v-for="(item,index) in element.attrs.summary" effect="plain" :key="index">{{item}}</el-tag>
      </template>
    </el-form-item>

    <el-form-item label="最大值" v-if="element.attrs.max_columns.length>0">
      <template #default>
        <el-tag v-for="(item,index) in element.attrs.max_columns" effect="plain" :key="index">{{item}}</el-tag>
      </template>
    </el-form-item>

    <el-form-item label="最小值" v-if="element.attrs.min_columns.length>0">
      <template #default>
        <el-tag v-for="(item,index) in element.attrs.min_columns" effect="plain" :key="index">{{item}}</el-tag>
      </template>
    </el-form-item>

    <el-form-item label="平均值" v-if="element.attrs.avg_columns.length>0">
      <template #default>
        <el-tag v-for="(item,index) in element.attrs.avg_columns" effect="plain" :key="index">{{item}}</el-tag>
      </template>
    </el-form-item>

    <el-form-item label="计数值" v-if="element.attrs.count_columns.length>0">
      <template #default>
        <el-tag v-for="(item,index) in element.attrs.count_columns" effect="plain" :key="index">{{item}}</el-tag>
      </template>
    </el-form-item>

    <el-form-item label="合并值" v-if="element.attrs.concat_columns.length>0">
      <template #default>
        <el-tag v-for="(item,index) in element.attrs.concat_columns" effect="plain" :key="index">{{item}}</el-tag>
      </template>
    </el-form-item>

    <el-form-item label="显示值" v-if="element.attrs.others.length>0">
      <template #default>
        <el-tag v-for="(item,index) in element.attrs.others" effect="plain" :key="index">{{item}}</el-tag>
      </template>
    </el-form-item>

    <el-form-item label="排序" v-if="element.attrs.dim_orders.length>0">
      <template #default>
        <el-tag v-for="(item,index) in element.attrs.dim_orders" effect="plain" :key="index">{{item}}</el-tag>
      </template>
    </el-form-item>


  </el-form>

  <squash-setting ref="squashSetting"></squash-setting>

</template>

<script>
import squashSetting from "@/components/etl/node/squash/squashSetting.vue";

export default {
  components: {
    squashSetting
  },
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    }
  },


}

</script>

<style scoped lang="scss">

.el-tag{
  margin: 4px;
}

</style>
<script setup lang="ts">
</script>