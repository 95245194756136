import {get, post, post2, put, put2, $delete} from '@/plugins/axios.js'

export default {
    post(params) {
        return post2('/api/etl', params);
    },
    list(params) {
        return get('/api/etl/list', params);
    },
    findById(id) {
        return get('/api/etl/findById', {id});
    },
    delete(id) {
        return $delete('/api/etl', {id});
    },
    listApp(params) {
        return get('/api/etl/listApp', params);
    },
    listForm(params) {
        return get('/api/etl/listForm', params);
    },
    formElements(params) {
        return get('/api/etl/formElements', params)
    },
    run(token, id, data) {
        return post2(`/api/etl/run/${token}/${id}`, data)
    },
    preview(token, id, params) {
        return post(`/api/etl/preview/${token}/${id}`, params)
    },
    getNodeColumns(id, params) {
        return post2(`/api/etl/getNodeColumns/${id}`, params);
    },
    getScripts(params){
        return get(`/api/etl/listScript`, params);
    },
}