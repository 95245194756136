const _ = require("lodash");

exports.install = function (app) {


    app.config.globalProperties.getSuggestFromTableData =  function (tableData) {
        let suggest = {}

        if (!tableData || tableData.length === 0)
            return {};

        tableData.forEach(item => {
            for (let key in item) {
                if (!suggest[key])
                    suggest[key] = new Set();
                if (item[key]) suggest[key].add(item[key]);
            }
        })

        for (let key in suggest)
            suggest[key] = Array.from(suggest[key]);

        return suggest;
    }

    app.config.globalProperties.blobToBase64 = function (blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    app.config.globalProperties.randomId = function () {
        return parseInt(new Date().getMilliseconds() + "" + Math.ceil(Math.random() * 100000)).toString(16);
    };


    app.config.globalProperties.copyText = function (text) {
        try {
            navigator.clipboard.writeText(text);
            this.notify('消息', '内容已复制到剪贴板', 'success')
        } catch (err) {
            this.notify('消息', '复制到剪贴板失败', 'error')
        }
    }

    app.config.globalProperties.confirm = function (html, confirm = () => {
    }, cancel = () => {
    }) {

        this.$messageBox.confirm(
            `<div style="color: #344262;padding: 20px 20px 20px 0">${html}</div>`,
            {
                confirmButtonText: '确 定',
                cancelButtonText: '取 消',
                type: 'warning',
                dangerouslyUseHTMLString: true,
                autofocus: false,
                showClose: false
            }
        ).then(confirm).catch(cancel)

    }

    app.config.globalProperties.prompt = function (title, content, value, confirm = () => {
    }, cancel = () => {
    }) {

        this.$messageBox.prompt(
            content, title,
            {
                confirmButtonText: '确 定',
                cancelButtonText: '取 消',
                dangerouslyUseHTMLString: true,
                inputType: 'textarea',
                inputValue: value,
                autofocus: true,
                showClose: false
            }
        ).then(({value}) => confirm(value)).catch(cancel)

    }


    app.config.globalProperties.message = function (message, type = 'info') {
        this.$message({
            message,
            type,
            plain: true,
        })
    }

    app.config.globalProperties.notify = function (title, message, type = 'info', duration = 3000) {
        this.$notification({
            title,
            message,
            type,
            duration: duration,
            offset: 0,
            position: 'top-right',
        })
    }

    /**
     * 通用 - 表单验证
     * @param form
     * @param rules
     * @returns {boolean}
     */
    app.config.globalProperties.validate = function (form, rules) {

        for (let i = 0; i < rules.length; i++) {
            let rule = rules[i]

            if (!form[rule.name]) {
                this.$message(rule.message)
                return false;
            }
        }

        return true;
    }

    //对象clone
    app.config.globalProperties.deepClone = function (obj) {
        return _.cloneDeep(obj);
    }
    app.config.globalProperties.current = function () {
        var now = new Date();
        var year = now.getFullYear();
        var month = now.getMonth() + 1; // 月份是从0开始的，所以需要+1
        var day = now.getDate();
        var hours = now.getHours();
        var minutes = now.getMinutes();
        var seconds = now.getSeconds();

        // 补零操作，确保月、日、时、分、秒都是两位数
        month = (month < 10 ? "0" : "") + month;
        day = (day < 10 ? "0" : "") + day;
        hours = (hours < 10 ? "0" : "") + hours;
        minutes = (minutes < 10 ? "0" : "") + minutes;
        seconds = (seconds < 10 ? "0" : "") + seconds;

        return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
    }

    app.config.globalProperties.current = function (format = "yyyy-MM-dd HH:mm:ss") {
        var now = new Date();
        var year = now.getFullYear();
        var month = now.getMonth() + 1; // 月份是从0开始的，所以需要+1
        var day = now.getDate();
        var hours = now.getHours();
        var minutes = now.getMinutes();
        var seconds = now.getSeconds();

        // 补零操作，确保月、日、时、分、秒都是两位数
        month = (month < 10 ? "0" : "") + month;
        day = (day < 10 ? "0" : "") + day;
        hours = (hours < 10 ? "0" : "") + hours;
        minutes = (minutes < 10 ? "0" : "") + minutes;
        seconds = (seconds < 10 ? "0" : "") + seconds;

        return format.replaceAll("yyyy", year).replaceAll("YYYY", year)
            .replaceAll("MM", month)
            .replaceAll("dd", day).replaceAll("DD", day)
            .replaceAll("HH", hours)
            .replaceAll("mm", minutes)
            .replaceAll("ss", seconds);
    }


    app.config.globalProperties.lastMonday = function (date) {

        const dayOfWeek = date.getDay() || 7;

        // 计算周一的日期
        const monday = new Date(date);
        monday.setDate(monday.getDate() - dayOfWeek + 1);


        return monday;
    }


    app.config.globalProperties.lastSunday = function (date) {

        const dayOfWeek = date.getDay() || 7;

        const sunday = new Date(date);
        sunday.setDate(sunday.getDate() - dayOfWeek + 7);


        return sunday;
    }

    app.config.globalProperties.dayOffset = function (date, offset) {
        const newDate = new Date(date); // 创建一个新的日期对象，以防修改原始日期
        newDate.setDate(newDate.getDate() + offset); // 设置新的日期，偏移offset天
        return newDate; // 返回新的日期对象
    }


    //打印格式化json字符串
    app.config.globalProperties.log = function (object) {
        console.log(JSON.stringify(object, null, 4))
    }
    //设置cookie
    app.config.globalProperties.setCookie = function (key, value, exdays = 7) {
        var exdate = new Date(); //获取时间
        exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
        //字符串拼接cookie
        window.document.cookie = key + "=" + value + ";path=/;expires=" + exdate.toGMTString();
    }
    //读取cookie
    app.config.globalProperties.getCookie = function (sname) {
        var acookie = document.cookie.split("; ");
        for (var i = 0; i < acookie.length; i++) {
            var arr = acookie[i].split("=");
            if (sname == arr[0]) {
                if (arr.length > 1) return unescape(arr[1]); else return undefined;
            }
        }
        return undefined;
    }
    //页面跳转
    //'app/'+1
    app.config.globalProperties.go = function (url, query, _blank = false) {

        if (_blank) {
            let routeUrl = this.$router.resolve({
                path: url, query: query
            });
            window.open(routeUrl.href, '_blank');
        } else {
            this.$router.push({
                path: url, query: query
            })
        }
    }
    app.config.globalProperties.UUID = function () {
        var d = new Date().getTime();
        if (window.performance && typeof window.performance.now === "function") {
            d += performance.now(); //use high-precision timer if available
        }
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            let r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }
    //你懂的
    app.config.globalProperties.base64Encode = function (content) {
        let Base64 = require('js-base64').Base64
        return Base64.encode(content);
    }
    app.config.globalProperties.base64Decode = function (content) {
        let Base64 = require('js-base64').Base64
        return Base64.decode(content)
    }
    //获取页面参数
    app.config.globalProperties.getParam = function (key) {
        let value = null;

        if (key in this.$route.query) {
            value = this.$route.query[key];
        } else if (key in this.$route.params) {
            value = this.$route.params[key];
        }

        return value;
    }

    //websocket加心跳
    app.config.globalProperties.ws = {
        websocket: {},
        data: 0,
        heart_rate: 3 * 1000, //30秒一次心跳
        timeout_heartbeat_client: null,
        timeout_heartbeat_server: null,
        timeout_reconnect: null,
        reconnect_count: 1,
        onOpenCallback: null,
        onCloseCallback: null,
        onMessageCallback: null,
        start: function () {
            console.log('[WS] Start')
            this.websocket = new WebSocket( `/socket`)
            this.websocket.onopen = this.onopen.bind(this);
            this.websocket.onerror = this.onerror.bind(this);
            this.websocket.onmessage = this.onmessage.bind(this);
            this.websocket.onclose = this.onclose.bind(this);
        },
        send(message) {
            this.websocket.send(message)
        },
        onopen: function () {
            this.heartbeat();
            this.reconnect_count = 1;
            if (this.onOpenCallback)
                this.onOpenCallback()
            console.log('[WS] The WebSocket connection succeeded.');
        },
        setOnOpenCallback(func) {
            if (func) this.onOpenCallback = func;
        },
        onerror: function (e) {
            console.error("[WS] The WebSocket connection failed:" + JSON.stringify(e));
            // Vue.prototype.$notify({
            // 	title: '连接已断开',
            // 	message: `正在第 ${this.reconnect_count} 次重新连接...`,
            // 	iconClass: 'el-icon-loading',
            // 	showClose: true,
            // 	duration: 7000
            // });
            //this.reconnect()
        },
        onmessage: function (event) {
            let obj = JSON.parse(event.data);
            switch (obj.type) {
                case 'heartbeat':
                    this.retHeartbeat();
                    break;
                default:
                    if (this.onMessageCallback) {
                        this.onMessageCallback(obj)
                    } else {
                        console.log("[WS] Received server messages ", obj)
                    }

            }
        },
        setOnMessageCallback(func) {
            if (func) this.onMessageCallback = func;
        },
        setOnCloseCallback(func) {
            if (func) this.onCloseCallback = func;
        },
        onclose: function (e) {
            this.websocket.close();
            if (this.onCloseCallback) this.onCloseCallback()
            console.log('[WS] The WebSocket connection close.');
        },
        reconnect: function () {
            if (this.lockReconnect) return;
            console.log('[WS] reconnect server')
            this.lockReconnect = true;
            this.reconnect_count += 1;
            this.timeout_reconnect && clearTimeout(this.timeout_reconnect);
            this.timeout_reconnect = setTimeout(() => {
                this.start()
                this.lockReconnect = false;
            }, 5000);
        },
        retHeartbeat: function () {
            clearTimeout(this.timeout_heartbeat_client);
            clearTimeout(this.timeout_heartbeat_server);
            this.heartbeat();
        },
        close() {
            setTimeout(() => {
                clearTimeout(this.timeout_heartbeat_client);
                clearTimeout(this.timeout_heartbeat_server);
                this.websocket.close()
            }, 1000);
        },
        heartbeat: function () {
            this.timeout_heartbeat_client && clearTimeout(this.timeout_heartbeat_client);
            this.timeout_heartbeat_server && clearTimeout(this.timeout_heartbeat_server);

            var _this = this;
            this.timeout_heartbeat_client = setTimeout(function () {

                if (_this.websocket && _this.websocket.readyState === 1)
                    _this.send(JSON.stringify({type: 'heartbeat'}));
                else
                    _this.reconnect();

                _this.timeout_heartbeat_server = setTimeout(() => {
                    _this.websocket.close();
                }, _this.heart_rate);

            }, this.heart_rate)
        }
    }
}
