import {defineStore} from 'pinia'
import {useFormStore} from "@/stores/form";
import {useUserStore} from "@/stores/user";
import {dynamicFunction} from "@/pojo/script";

import _ from 'lodash'

const isRead = function (element) {

    if (element.type === 'layout') {
        if (element.tag === 'hbox') {
            let children = [...element.list1, ...element.list2, ...element.list3, ...element.list4];
            return children.some(some => some.type === 'layout' || (this.rule[some.id]?.isRead || false));
        } else {
            return true;
        }

    } else {
        return this.rule[element.id]?.isRead || false;
    }
}
const isWrite = function (element) {
    return this.rule[element.id]?.isWrite || false;

}
const isRequired = function (element) {
    return this.rule[element.id].isRequired;
}
const isHide = function (element, data) {


    try {
        return !dynamicFunction(element.hide.script.value, data)
    } catch (e) {
        return true;
    }
}
const isDisabled = function (element, data) {
    try {
        if (!element.disabled.script.value)
            return false;

        return dynamicFunction(element.disabled.script.value, data)
    } catch (e) {
        // console.error('isDisabled',e)
        // console.error(element.hide.script.value)
        // console.log(JSON.stringify(data,null,4))
        return false;
    }
}
const validate = function (data, success, error) {
    //校验必填字段
    for (let key in this.rule) {
        if (this.rule[key].isRequired && (data[key] === undefined || data[key] === null || data[key].toString().trim().length === 0)) {
            let message = `请填写「${this.columns.find(find => find.id === key).label}」`
            error(message, key);
            return;
        }
    }

    //自定义校验规则
    for (let validate of this.validates.filter(filter => filter.enable)) {
        try {

            let params = data;
            if (validate.source && validate.source !== 'default') {
                for (let item of data[validate.source]) {
                    let cloneData = _.cloneDeep(data);
                    Object.keys(cloneData).forEach(key => {
                        if (key.startsWith("subForm"))
                            delete cloneData[key];
                    })

                    params = {...cloneData, ...item}

                    if (dynamicFunction(validate.script.value, params)) {
                        error(validate.error);
                        return;
                    }
                }
            } else {

                if (dynamicFunction(validate.script.value, params)) {
                    error(validate.error);
                    return;
                }
            }


        } catch (e) {
            console.error(e,validate.script.value)
            error(validate.error);
            return;
        }
    }

    success();
}
const getType = function (element) {
    return this.isWrite(element) ? 'fill' : 'detail'
}
const isShowEditButton = function () {
    return Object.keys(this.rule).length > 0 && Object.values(this.rule).some(some => some.isWrite);
}

export const useFormAuthenticationStore = defineStore('formAuthentication', {
    state: () => {
        return {}
    },
    getters: {},
    actions: {
        add(form) {
            let rule = form.setting.form.fields.find(find => find.title === '新增数据权限' && find.system).fields;
            let validates = form.setting.form.validate;
            let columns = useFormStore().columns(form)
            return {
                rule, validates, columns, isRead, isWrite, isRequired, validate, getType, isHide, isDisabled
            }
        },
        view(form) {
            let userStore = useUserStore();

            let rule = form.setting.form.fields.find(find => !find.system && find.role.some(some => userStore.isRole(some)))?.fields ||
                form.setting.form.fields.find(find => find.title === '查看编辑数据权限' && find.system).fields;
            return {
                rule, isRead,
                isWrite(element) {
                    return false;
                },
                isRequired(element) {
                    return false;
                },
                getType(element) {
                    return 'detail'
                }, isHide, isDisabled
            }
        },
        edit(form) {
            let userStore = useUserStore();
            let validates = form.setting.form.validate;
            let rule = form.setting.form.fields.find(find => !find.system && find.role.some(some => userStore.isRole(some)))?.fields ||
                form.setting.form.fields.find(find => find.title === '查看编辑数据权限' && find.system).fields;
            let columns = useFormStore().columns(form)
            return {
                rule,
                validates,
                columns,
                isRead,
                isWrite,
                isRequired,
                validate,
                getType,
                isShowEditButton,
                isHide,
                isDisabled
            }
        },
        workFlow(form, node) {
            let rule = node.attrs.operation;
            let validates = form.setting.form.validate;
            let columns = useFormStore().columns(form)
            return {
                rule,
                validates,
                columns,
                isRead,
                isWrite,
                isRequired,
                validate,
                getType,
                isShowEditButton,
                isHide,
                isDisabled
            }
        },

    },
    persist: {
        enabled: false
    }
})