<template>
  <div>

    <template v-if="context.parent && context.parent.tag==='subForm'">
      <user-select v-if="type==='fill'" v-model="data[element.id]" :multiple="element.attrs.multiple"></user-select>
      <div v-else-if="type==='detail'">
        <div v-if="data[element.id]" style="display: flex;align-items: center;">
          <el-icon>
            <User></User>
          </el-icon>
          <span style="margin-left: 4px">{{ users.map(mapper => mapper.real_name).join(',') }}</span>
        </div>
        <span v-else></span>
      </div>
    </template>

    <el-form-item v-else :required="context.authentication.isRequired(element)">
      <template #label>
      <span :style="{color:'#344262','font-weight':context.form.setting.style.PC.title}">
        {{ element.label }}
         <form-element-tooltip :element="element"></form-element-tooltip>
      </span>
      </template>
      <user-select v-if="type==='fill'" v-model="data[element.id]" :multiple="element.attrs.multiple"></user-select>
      <div v-else-if="type==='detail'" class="design-detail">
        <div v-if="data[element.id]" style="display: flex;align-items: center;">
          <el-icon>
            <User></User>
          </el-icon>
          <span style="margin-left: 4px">{{ users.map(mapper => mapper.real_name).join(',') }}</span>
        </div>
        <span v-else>&nbsp;</span>
      </div>
    </el-form-item>
  </div>

</template>

<script>

import {mapStores} from "pinia";
import {useFlowStore} from "@/stores/flow";
import {useUserStore} from "@/stores/user";
import userSelect from "@/components/common/userSelect.vue";
import FormElementTooltip from "@/components/common/formElementTooltip.vue";

export default {
  components: {
    FormElementTooltip,
    userSelect
  },
  props: {
    context: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      data: {},
      type: '',
      users: [],
    }
  },
  computed: {
    ...mapStores(useFlowStore, useUserStore)
  },
  created() {
    this.data = this.context.data;
    this.type = this.flowStore.typeOfElement(this.element, this.context);

    if (this.type === 'detail') {
      this.findUserByIds();
    } else if (this.type === 'fill' && !this.data[this.element.id] && this.element.default.select === 'submitter') {
      this.data[this.element.id] = this.userStore.user.id;
    }

  },
  methods: {
    findUserByIds() {
      let value = this.data[this.element.id];
      if (value) {

        if (typeof value === 'string') {
          this.userStore.findById(value).then(res => {
            this.users.push(res.data);
          })
        } else {
          value.forEach(userId => {
            this.userStore.findById(userId).then(res => {
              this.users.push(res.data);
            })
          })
        }
      }
    }
  }
}

</script>

<style scoped lang="scss">

</style>