

<template>

  <el-dialog v-model="dialogVisible" title="填充" :close-on-click-modal="false" width="920px"
             style="border-radius: 10px"
             append-to-body>





  </el-dialog>

</template>

<script>

import {mapStores} from "pinia";
import {useEtlStore} from "@/stores/etl";
export default {
  computed:{
    ...mapStores(useEtlStore)
  },
  data() {
    return {
      dialogVisible:false,
    }
  },
  methods:{
    call(node, data) {
      this.node = node;
      this.data = data;
      this.dialogVisible = true;
      this.$nextTick(() => this.initColumns())
    },
    initColumns() {
      this.etlStore.getNodeColumns(this.node.id, this.data).then(res => {
        if (res.code === 200) {
          this.columns = res.data.map(mapper => {
            return {
              name: mapper.name,
              nodeName: mapper.nodeName,
              nodeType: mapper.nodeType
            }
          });
        }
      })
    },


  }

}

</script>

<style scoped lang="scss">



</style>