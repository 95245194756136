import {
    get,
    post,
    post2,
    put,
    put2,
    $delete
} from '@/plugins/axios.js'

export default {


    post(params) {
        return post2('/api/menu', params);
    },
    updatePermissions(params) {
        return put('/api/menu/updatePermissions', params);
    },
    list(params) {
        return get('/api/menu', params)
    },
    listByPermission(params) {
        return get('/api/menu/listByPermission', params)
    },
    groups(params) {
        return get('/api/menu/groups', params)
    },
    findById(id, params) {
        return get(`/api/menu/findById/${id}`, params)
    },
    delete(id, params) {
        return $delete(`/api/menu/${id}`, params)
    },
    rename(id, name) {
        return put2(`/api/menu/rename/${id}`, {name})
    },
    moveTo(source, to, params) {
        return post(`/api/menu/moveTo`, {
            source,
            to
        });
    },
    sort(ids) {
        return post(`/api/menu/sort`, {
            ids
        });
    },
    copyTo(applicationId, id, company, name) {
        return post(`/api/menu/copyTo/${applicationId}`, {id, company, name});
    }


}
