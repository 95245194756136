<template>

  <el-dialog title="连接" v-model="dialogVisible" :close-on-click-modal="false" append-to-body
             style="border-radius: 10px">
    <div style="padding: 10px;" v-if="node">
      <el-form v-if="inputs.length===2" :inline="true" label-width="72px">
        <el-form-item label="连接类型">
          <el-select v-model="attrs.joinType" style="width: 200px">
            <el-option label="左连接" value="left"></el-option>
            <el-option label="右连接" value="right"></el-option>
            <el-option label="内连接" value="inner"></el-option>
            <el-option label="交叉连接" value="cross"></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <el-tabs v-model="activeName">
        <el-tab-pane label="关联条件" name="关联条件">
          <el-button icon="Plus" type="primary" link style="margin:0 20px 20px 10px;float: right"
                     @click="addAssociated">
            添加关联条件
          </el-button>
          <el-table v-if="inputs.length===2" :data="node.attrs.joins" stripe
                    :height="isFullscreen?'66vh':'30vh'">
            <el-table-column label="输入源1" align="center">
              <template #default="scope">
                <el-select v-model="scope.row[0]" filterable>
                  <el-option v-for="(item,index) in source1" :key="index" :value="item.name">
                    <span style="float: left">{{ item.name }}</span>
                    <span style="float: right; color: #8492a6; font-size: 12px">{{
                        item.nodeName
                      }} ({{ item.nodeType }})</span>
                  </el-option>
                </el-select>
              </template>

            </el-table-column>
            <el-table-column label="输入源2" align="center">
              <template #default="scope">
                <el-select v-model="scope.row[1]" filterable>
                  <el-option v-for="(item,index) in source2" :key="index" :value="item.name">
                    <span style="float: left">{{ item.name }}</span>
                    <span style="float: right; color: #8492a6; font-size: 12px">{{
                        item.nodeName
                      }} ({{ item.nodeType }})</span>
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template #default="scope">
                <el-button icon="Remove" text style="color: #ff557f;" title="删除" type="danger"
                           @click="delAssociated(scope.$index)"></el-button>
                <el-button icon="Refresh" link title="重置"
                           @click="resetAssociated(scope.$index)"></el-button>
              </template>
            </el-table-column>
          </el-table>
          <error ref="error" style="margin-top: 40px;"></error>
        </el-tab-pane>
        <el-tab-pane label="字段设置" name="字段设置">
          <columnSelect v-if="activeName==='字段设置' && attrs" v-model="attrs.selects" :columns="allColumns" :is-fullscreen="isFullscreen"></columnSelect>
        </el-tab-pane>
      </el-tabs>

    </div>
  </el-dialog>


</template>

<script>
import {mapStores} from "pinia";
import {useEtlStore} from "@/stores/etl";
import error from '@/components/etl/node/error.vue'
import columnSelect from "./columnSelect";

export default {
  components: {
    error, columnSelect
  },
  computed: {
    ...mapStores(useEtlStore)
  },
  props: {},
  data() {
    return {
      node: null,
      data: null,
      attrs: {},
      /** 输入源字段 */
      source1: [],
      source2: [],
      allColumns: [],
      inputs: [],
      activeName: '关联条件',
      dialogVisible: false,
      isFullscreen: false,
    }
  },
  methods: {
    async call(node, data) {
      this.node = node;
      this.data = data;
      this.attrs = this.node.attrs;

      try {
        this.$refs.error.close()
      } catch (e) {
      }

      this.inputs = this.data.lineList.filter(filter => filter.to === this.node.id);
      this.dialogVisible = true;
      if (this.inputs.length !== 2) {
        this.$nextTick(() => this.$refs.error.show('输入源小于2，组件无法正常运行'))
        this.node.state = 'error'
      } else {
        await this.initColumns1(this.inputs[0].from, this.data);
        await this.initColumns2(this.inputs[1].from, this.data);
      }
    },
    save() {
      this.data.attrs = this.attrs;
      this.dialogVisible = false;
    },
    async initColumns1(id, data) {
      await this.etlStore.getNodeColumns(id, data).then(res => {
        if (res.code === 200) {
          this.source1 = res.data
          this.source1.forEach(item => {
            this.allColumns.push({
              id, name: item.name, nodeName: item.nodeName, nodeType: item.nodeType, alias: ''
            })
          })
        }
      })
    },
    async initColumns2(id, data) {
      await this.etlStore.getNodeColumns(id, data).then(res => {
        if (res.code === 200) {
          this.source2 = res.data
          this.source2.forEach(item => {
            this.allColumns.push({
              id, name: item.name, nodeName: item.nodeName, nodeType: item.nodeType, alias: ''
            })
          })
        }
      })
    },
    addAssociated() {
      this.node.attrs.joins.push(["", ""])
    },
    delAssociated(index) {
      this.node.attrs.joins.splice(index, 1)
    },
    resetAssociated(index) {
      this.node.attrs.joins[index][0] = ""
      this.node.attrs.joins[index][1] = ""

      this.node.attrs.joins = this.deepClone(this.node.attrs.joins)
    },
    beforeSave() {
      //未设置字段自动赋值默认字段
      if (!this.node.attrs.selects || this.node.attrs.selects.length === 0) {
        let array = []
        this.allColumns.forEach(column => {
          if (!array.some(item => item.name === column.name && item.nodeName !== column.nodeName))
            array.push(column)
        });

        this.node.attrs.selects = array.map(item => {
          let {id, name, alias} = item;
          return {id, name, alias};
        })

      } else {
        //重名字段抛出异常
        this.node.attrs.selects.forEach(item => {
          let name = item.alias ? item.alias : item.name;
          let filter = this.node.attrs.selects.filter(filter => {
            let name2 = filter.alias ? filter.alias : filter.name;
            return name === name2;
          });

          if (filter.length > 1) {
            throw {
              code: -1,
              message: `重名的字段 ${filter[0].alias ? filter[0].alias : filter[0].name} , 若保留请正确设置别名`
            }
          }
        });
      }


    }
  }
}
</script>

<style scoped>
:deep(.el-table thead) {
  color: #1F1C2C;
}

:deep(.el-tabs__item) {
  font-size: 13px;
}

li {
  padding: 0 4px 0 10px;
  font-size: 13px;
}
</style>
