<template>

  <div class="tr" v-if="type==='default'&& !parent">
    <div class="th">{{element.label}}</div>
    <div class="td">{{data[element.id]}}</div>
  </div>
  <template v-else-if="type==='excel'">
    <div class="th">{{element.label}}</div>
    <div class="td">{{data[element.id]}}</div>
  </template>
  <span v-else>{{data[element.id]}}</span>

</template>

<script>


export default {
  components: {},
  props: {
    form: {
      type: Object,
    },
    data: {
      type: Object,
    },
    element: {
      type: Object
    },
    parent: {
      type: Object
    },
    type: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {}
  },
  created() {
  }
}

</script>

<style scoped lang="scss">

</style>