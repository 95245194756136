<template>

  <el-button @click="$refs.calcSetting.call(element,data)" icon="Filter" link type="primary" size="small">计算设置
  </el-button>


  <el-table :data="element.attrs.items" style="width: 100%;margin-top: 20px;background: white;--el-table-tr-bg-color:white;--el-table-header-bg-color:white"
            :header-cell-style="{color:'black'}" border size="small">
    <el-table-column label="字段" prop="name" :width="100" align="center">

    </el-table-column>

    <el-table-column label="脚本" prop="value" align="center">

    </el-table-column>

  </el-table>

  <calc-setting ref="calcSetting"></calc-setting>
</template>

<script>
import calcSetting from "@/components/etl/node/calc/calcSetting.vue";
export default {
  components: {
    calcSetting
  },
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    }
  },


}
</script>

<style scoped lang="scss">

</style>