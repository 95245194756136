<template>

  <el-dialog v-model="dialogVisible" title="过滤" :close-on-click-modal="false" width="920px"
             style="border-radius: 10px"
             append-to-body>
    <div>
      <div style="margin-top: 10px">
        <el-button icon="Plus" type="primary" link @click="add">添加过滤条件</el-button>
      </div>
      <el-divider></el-divider>
      <div v-if="node">
        <div v-for="(filter,index) in filters" class="item" :key="index">
          <el-select v-if="index>0" v-model="filter.associated" small style="width: 60px;">
            <el-option label="且" value="AND"></el-option>
            <el-option label="或" value="OR"></el-option>
          </el-select>

          <span v-else style="margin-left: 36px">当</span>
          <el-select :style="whenStyle(index,node)" v-model="filter.fieldName" small filterable placeholder="选择字段">
            <el-option v-for="(item,index) in columns" :key="index" :value="item.name">
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 12px">{{ item.nodeName }}</span>
            </el-option>
          </el-select>
          的
          <el-select v-model="filter.fun" small filterable placeholder="选择函数" @change="handleChangeCs(index)"
                     style="width: 150px;margin-left: 4px" :empty-values="[null, undefined]">
            <el-option v-for="(item,index) in funs" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>

          <el-select v-model="filter.cs" small filterable placeholder="选择条件" @change="handleChangeCs(index)"
                     style="width: 120px">
            <el-option v-for="(item,index) in cs" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>


          <el-select v-if="filter.cs.indexOf('CC')!==-1" v-model="filter.value" small filterable placeholder="选择字段" style="width: 20%">
            <el-option v-for="(item,index) in columns" :key="index" :value="item.name">
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 12px">{{ item.nodeName }}</span>
            </el-option>
          </el-select>
          <el-input v-else-if="filter.cs.indexOf('NULL')===-1 && filter.cs.indexOf('EMPTY')===-1 && filter.cs.indexOf('BETWEEN')===-1"
              v-model="filter.value" small style="width: 20%;margin-left: 20px;"
              :placeholder="filter.cs==='IN'?'多个值英文逗号分割':'值'"
              @blur="changeType(index,-1)"></el-input>

          <span v-else-if="filter.cs.indexOf('BETWEEN')>=0">
            <el-input v-model="filter.value[0]" small style="width: 9%;margin-left: 20px;"
                      placeholder="" @blur="changeType(index,0)"></el-input>&nbsp;-&nbsp;
            <el-input v-model="filter.value[1]" small style="width: 9%;"
                      placeholder="" @blur="changeType(index,1)"></el-input>
				  </span>


          <span style="float: right;margin-right: 10px;display: flex;align-items: center;height: 36px">
          <el-button icon="Remove" link @click="del(index)" title="删除" type="danger"></el-button>
          <el-button icon="RefreshRight" link @click="reset(index)" title="重置"></el-button>
        </span>
        </div>
        <br/>
      </div>
    </div>
  </el-dialog>


</template>

<script>
import {mapStores} from "pinia";
import {useEtlStore} from "@/stores/etl";
import {getCs} from './cs.js'
import {getFuns} from './funs.js'

export default {
  computed: {
    ...mapStores(useEtlStore),
  },
  data() {
    return {
      node: null,
      data: null,
      filters: [],
      /** 可选字段 */
      columns: [],
      cs: getCs(),
      funs: getFuns(),
      dialogVisible: false
    }
  },
  methods: {
    call(node, data) {
      this.node = node;
      this.data = data;
      this.filters = this.node.attrs.filters;
      if (this.filters.length === 0) {
        this.add();
      }

      this.dialogVisible = true;
      this.$nextTick(() => this.initColumns())
    },
    save() {
      this.node.attrs.filters = this.filters;
      this.dialogVisible = false;
    },
    whenStyle(index) {
      return {
        marginLeft: (index === 0 && this.filters.length > 1) ? '50px' : '20px',
        marginRight: '4px',
        width: '180px'
      }
    },
    initColumns() {
      this.etlStore.getNodeColumns(this.node.id, this.data).then(res => {
        if (res.code === 200) {
          this.columns = res.data;
        }
      })
    },
    add() {
      this.filters.push({
        fieldName: '',
        cs: 'EQUALS',
        fun: '',
        value: '',
        associated: 'AND'
      })
    },
    del(index) {
      if (this.filters.length > 1)
        this.filters.splice(index, 1)
    },
    reset(index) {
      this.filters[index].fieldName = ""
      this.filters[index].cs = "EQUALS"
      this.filters[index].value = ""
      this.filters[index].fun = ""
    },
    handleChangeCs(index) {
      if (this.filters[index].cs.indexOf('BETWEEN') >= 0) {
        this.filters[index].value = ["", ""]
      } else {
        this.filters[index].value = ""
      }
    },
    changeType(index, array_index) {

      if (array_index >= 0) {
        try {
          if (this.filters[index].value[array_index]) {
            this.filters[index].value[array_index] = Number(this.filters[index].value[array_index]) ?
                Number(this.filters[index].value[array_index]) : this.filters[index].value[array_index]
          }

        } catch (e) {

        }
      } else {
        try {
          this.filters[index].value = Number(this.filters[index].value) ?
              Number(this.filters[index].value) : this.filters[index].value
        } catch (e) {

        }
      }

    }
  }
}
</script>

<style scoped lang="scss">
:deep( .el-select) {
  margin-left: 20px;
}

:deep( .el-tag ){
  margin: 4px;
}

.item {
  background-color: #F7F7F8;
  border-radius: 6px;
  padding: 6px 2px 6px 2px;
  margin: 10px 10px 10px 10px;
}

li {
  padding: 0 4px 0 10px;
  font-size: 13px;
}
</style>
