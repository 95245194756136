export function getFuns() {
    return [
        {label: '值', value: ""},
        {label: '长度', value: "LENGTH(%s)"},
        {label: '小写值', value: "LOWER(%s)"},
        {label: '大写值', value: "UPPER(%s)"},
        {label: '去除首尾空格', value: "TRIM(%s)"},
        {label: '绝对值', value: "ABS(%s)"},
        {label: '四舍五入', value: "ROUND(%s)"},
    ]
}