<template>

  <div class="tr"  v-if="type==='default'&& !parent">
    <div class="th">{{ element.label }}</div>
    <div class="td">{{ title }}</div>
  </div>
  <template v-else-if="type==='excel'">
    <div class="th">{{ element.label }}</div>
    <div class="td">{{ title }}</div>
  </template>
  <span v-else>{{ title }}</span>

</template>


<script>
import {mapStores} from "pinia";
import {useFormStore} from "@/stores/form";

export default {
  components: {},
  computed: {
    ...mapStores(useFormStore)
  },
  props: {
    data: {
      type: Object,
    },
    element: {
      type: Object
    },
    parent: {
      type: Object
    },
    form: {
      type: Object
    },
    type: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {
      title:''
    }
  },
  created() {

    if (this.data[this.element.id])
      this.formStore.findTitleById(this.element.attrs.form_id, this.data[this.element.id]).then(res=>{
        this.title = res.data
      })
  }
}

</script>

<style scoped lang="scss">

</style>