<template>
  <full-dialog ref="fullDialog" :close-on-press-escape="true" :close-on-click-modal="true">
    <div class="affix-container">
      <div v-if="loading" v-loading="loading"
           element-loading-text=" 正在读取文件内容，请稍候..."
           style="width: 800px;height: 280px;margin: 0 auto">
      </div>
      <div ref="container" v-html="content" style="text-align: center;
  background-color: white"
           @mousedown.prevent="handleMouseDown"
           @mousemove="handleMouseMove"
           @mouseup="handleMouseUp"
           @mouseleave="handleMouseLeave"></div>
    </div>
    <div v-if="!loading" class="zoom-buttons">
      <el-button icon="ZoomIn" @click="zoomIn" style="color: white;font-size: 25px" link></el-button>
      <el-button icon="ZoomOut" @click="zoomOut" style="color: white;font-size: 25px" link></el-button>
      <el-button icon="Download" @click="download()" style="color: white;font-size: 25px" link></el-button>
    </div>
  </full-dialog>
</template>
<script>
import {mapStores} from "pinia";
import {useAttachmentStore} from "@/stores/attachment";
import fullDialog from "@/components/common/fullDialog.vue";

export default {
  components: {fullDialog},
  data() {
    return {
      loading: false,
      id: '',
      title: '',
      dialogVisible: false,
      content: '',
      height: 0,
      scale: 1.0,
      isDragging: false,
      dragStart: {x: 0, y: 0},
      position: {x: 0, y: 0},
      rafId: null
    }
  },
  computed: {
    ...mapStores(useAttachmentStore)
  },
  created() {
    this.height = window.innerHeight;
  },
  watch: {},
  methods: {
    call(id, title) {
      this.id = id;
      this.title = title;
      this.loading = true;
      this.height = window.innerHeight;
      this.$refs.fullDialog.call();
      this.attachmentStore.preview(id).then(res => {
        this.loading = false;
        if (res.status === 'success') {
          this.content = res.data;
        } else {
          this.$refs.fullDialog.close();
        }
      });
    },
    download() {
      this.attachmentStore.download(this.id, this.title);
    },
    zoomIn() {
      this.scale += 0.02;
      this.scale = Math.min(this.scale, 5); // 限制最大缩放比例
      this.updateTransform();
    },
    zoomOut() {
      this.scale -= 0.02;
      this.scale = Math.max(this.scale, 0.1); // 限制最小缩放比例
      this.updateTransform();
    },
    handleMouseDown(event) {
      this.isDragging = true;
      this.dragStart.x = event.clientX - this.position.x;
      this.dragStart.y = event.clientY - this.position.y;
    },
    handleMouseMove(event) {
      if (this.isDragging) {
        this.position.x = event.clientX - this.dragStart.x;
        this.position.y = event.clientY - this.dragStart.y;
        this.scheduleUpdate();
      }
    },
    handleMouseUp() {
      this.isDragging = false;
      this.cancelScheduledUpdate();
    },
    handleMouseLeave() {
      this.isDragging = false;
      this.cancelScheduledUpdate();
    },
    updateTransform() {
      this.$refs.container.style.transform = `translate(${this.position.x}px, ${this.position.y}px) scale(${this.scale})`;
    },
    scheduleUpdate() {
      if (!this.rafId) {
        this.rafId = requestAnimationFrame(() => {
          this.updateTransform();
          this.rafId = null;
        });
      }
    },
    cancelScheduledUpdate() {
      if (this.rafId) {
        cancelAnimationFrame(this.rafId);
        this.rafId = null;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.affix-container {
  position: relative; /* 确保父元素具有相对定位 */
  width: 100%;
  height: calc(100vh - 40px);
  overflow: auto;
}

.zoomable-div {
  position: absolute;
  top: 50%; /* 根据需要调整 */
  left: 50%; /* 根据需要调整 */
  transform: translate(-50%, -50%) scale(1); /* 初始缩放比例为1 */
  transition: transform 0.2s ease; /* 添加过渡效果 */
  width: 100%; /* 确保内容宽度适应父元素 */
  height: 100%; /* 确保内容高度适应父元素 */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.zoom-buttons {
  position: fixed; /* 固定按钮位置 */
  bottom: 50px; /* 距离底部50px */
  left: 50%; /* 水平居中 */
  transform: translateX(-50%); /* 使按钮居中 */
  background-color: #66676B;
  width: 200px;
  border-radius: 30px;
  text-align: center;
  padding: 10px 10px;
  z-index: 10001;
  display: flex;
  justify-content: space-around;
}

.zoom-buttons button {
  color: white;
  font-size: 30px;
  cursor: pointer;
}

</style>


