<template>

  <div>

    <template v-if="context.parent && context.parent.tag==='subForm'">
      <el-input v-if="type==='fill'" v-model="data[element.id]" :placeholder="element.attrs.placeholder"
                :disabled="context.authentication.isDisabled(element,data)"></el-input>
      <div v-else-if="type==='detail'">{{ data[element.id] || "⠀" }}</div>
    </template>

    <el-form-item v-else :required="context.authentication.isRequired(element)">
      <template #label>
      <span :style="{ color:'#344262','font-weight':context.form.setting.style.PC.title}">
        {{ element.label }}
        <form-element-tooltip :element="element"></form-element-tooltip>
      </span>
      </template>
      <el-input v-if="type==='fill'" v-model="data[element.id]" :placeholder="element.attrs.placeholder"
                :disabled="context.authentication.isDisabled(element,data)"></el-input>
      <div v-else-if="type==='detail'" class="design-detail">{{ data[element.id] || "⠀" }}</div>
    </el-form-item>
  </div>


</template>

<script>

import {mapStores} from "pinia";
import {useFlowStore} from "@/stores/flow";
import FormElementTooltip from "@/components/common/formElementTooltip.vue";
import {dynamicFunction} from "@/pojo/script";

export default {
  components: {FormElementTooltip},
  props: {
    element: {
      type: Object,
      default: () => {
      }
    },
    context: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      data: {},
      type: '',
    }
  },
  computed: {
    ...mapStores(useFlowStore)
  },
  created() {
    this.data = this.context.data;
    this.type = this.flowStore.typeOfElement(this.element, this.context);
  },
  mounted() {
    this.initWatch();
  },
  methods: {
    initWatch() {

      if (this.type === 'fill' && this.element.default.select === 'script' && this.element.default.script.value) {
        if (!this.data[this.element.id]) {
          try {
            this.data[this.element.id] = dynamicFunction(this.element.default.script.value, {...this.data, ...this.context.rawData});
          } catch (e) {
            console.error(e);
          }
        }

        if (this.context.rawData) {
          Object.keys(this.context.rawData).forEach(key => {
            if (this.element.default.script.value.includes(key)) {

              this.$watch(`context.rawData.${key}`, (newValue, oldValue) => {
                try {
                  this.data[this.element.id] = dynamicFunction(this.element.default.script.value, {...this.data, ...this.context.rawData});
                } catch (e) {
                  console.error(this.element.default.script.value, e)
                }
              }, {
                deep: true,
                immediate: false
              });
            }
          });
        }


        Object.keys(this.data).forEach(key => {

          if (this.element.default.script.value.includes(key)) {

            this.$watch(`data.${key}`, (newValue, oldValue) => {
              try {
                this.data[this.element.id] = dynamicFunction(this.element.default.script.value, {...this.data, ...this.context.rawData});
              } catch (e) {
                console.error(this.element.default.script.value, e)
              }
            }, {
              deep: true,
              immediate: false
            });

          }
        })


      }
    },

  }
}

</script>

<style scoped lang="scss">

</style>