<template>


  <el-button @click="$refs.filterCondition.call(element,data)" icon="Filter" link type="primary" size="small">条件设置</el-button>


  <el-table :data="element.attrs.filters"  style="width: 100%;margin-top: 20px;background: white;--el-table-tr-bg-color:white;--el-table-header-bg-color:white"
            :header-cell-style="{color:'black'}" border size="small">

    <el-table-column label="关联" align="center">
      <template #default="scope">
        {{scope.row.associated==='AND'?'且 ':'或 '}}
      </template>
    </el-table-column>
    <el-table-column label="字段名">
      <template #default="scope">
        {{scope.row.fieldName}}
      </template>
    </el-table-column>
    <el-table-column label="条件">
      <template #default="scope">
        {{cs2(scope.row.cs)}}
      </template>
    </el-table-column>
    <el-table-column label="值">
      <template #default="scope">
        {{scope.row.value}}
      </template>
    </el-table-column>

  </el-table>

  <filter-setting ref="filterCondition"></filter-setting>
</template>

<script>
import {getCs} from "./cs";
import filterSetting from "@/components/etl/node/filter/filterSetting.vue";

export default {
  components: {
    filterSetting
  },
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      cs: getCs()
    }
  },
  methods: {
    cs2(cs) {
      return this.cs.find(find => find.value === cs).label;
    }
  }
}

</script>

<style scoped lang="scss">

</style>