import {defineStore} from 'pinia'
import {useUserStore} from "@/stores/user";
import etlController from "@/controller/etlController";
import {items} from "@/pojo/data-design-form-aside";
import {download} from "@/plugins/axios";

const tags = items.flatMap(item => item.children).map(item => {
    return {
        name: item.name,
        tag: item.tag
    }
})

export const useEtlStore = defineStore('etl', {
    state: () => {
        return {}
    },
    getters: {},
    actions: {
        async post(document) {
            return await etlController.post(document)
        },
        async list(keyword) {
            return await etlController.list()
        },
        async delete(id) {
            return await etlController.delete(id)
        },
        async findById(id) {
            return await etlController.findById(id)
        },
        async listApp() {
            return await etlController.listApp()
        },
        async listForm(id) {
            return await etlController.listForm({id})
        },
        async formElements(id) {
            return await etlController.formElements({id})
        },
        columns(elements) {


            let columns = []
            elements.filter(filter => filter.type !== 'layout' || filter.tag === 'hbox').forEach(item => {
                if (item.tag === 'hbox') {
                    [...item.list1, ...item.list2, ...item.list3, ...item.list4].forEach(component => {
                        let translate = {
                            id: component.id,
                            name: component.label,
                            alias: '',
                            tag: component.tag,
                        }

                        translate.tagName = tags.find(tag => tag.tag === translate.tag).name
                        translate.children = this.children(component)
                        columns.push(translate)
                    })

                } else {
                    let translate = {
                        id: item.id,
                        name: item.label,
                        alias: '',
                        tag: item.tag,
                    }

                    translate.tagName = tags.find(tag => tag.tag === translate.tag).name
                    translate.children = this.children(item)
                    columns.push(translate)


                }
            });

            //创建时间加上attrs.format属性，是为了simpleCondition组件可以正常读取
            columns.push({
                id: "_property.create_time",
                name: '创建时间',
                alias: '',
                tag: 'date',
                tagName: '时间'
            })
            columns.push({
                id: "_property.submitter_id",
                name: '提交人',
                alias: '',
                tag: 'people',
                tagName: '人员'
            });

            columns.unshift({
                id: "id",
                name: "id",
                alias: '',
                tag: "id",
                tagName: "主键",
            })

            return columns;
        },
        children(parent) {
            if (!parent.children) {
                return [];
            }

            return parent.children.map(mapper => {

                let translate = {
                    id: mapper.id,
                    parent: parent.id,
                    name: mapper.label,
                    alias: '',
                    tag: mapper.tag,
                }

                translate.tagName = tags.find(tag => tag.tag === translate.tag).name
                return translate;
            })
        },
        alias(elements, columns) {
            elements.forEach(element => {
                let match = columns.find(find => find.id === element.id);
                if (match) {
                    element.alias = match.alias;
                }

                if (element.children) {
                    this.alias(element.children, columns);
                }
            })
        },
        run(token, id, data) {
            return etlController.run(token, id, data)
        },
        preview(token, id, params) {
            return etlController.preview(token, id, params)
        },
        download(fileName, token, id, count, params) {
            return download(fileName, `/api/etl/previewDownload/${token}/${id}/${count}`, params, null, null, "post")
        },
        getNodeColumns(id,data){
            return etlController.getNodeColumns(id,data)
        },
        getScripts(){
            return etlController.getScripts();
        },
    },
    persist: {
        enabled: false
    }
})