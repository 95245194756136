<template>


  <div>
    <template v-if="context.parent && context.parent.tag==='subForm'">
      <el-autocomplete v-if="type==='fill'" v-model="value" placeholder="请选择" style="width: 100%" :teleported="true"
                       :fetch-suggestions="querySearch" @select="handleSelect" clearable @clear="data[element.id]=''">
        <template #suffix>
          <el-button v-prevent-re-click icon="Connection" style="font-size: 16px" link
                     @click.stop="handleClick"></el-button>
        </template>
        <template #default="{ item }">
          <div class="value">{{ item.value }}</div>
        </template>
      </el-autocomplete>
      <div v-else-if="type==='detail'">
        <span v-if="value && value==='表单已删除'" style="color: #E57470">{{ value }}</span>
        <span v-else @click="handleClickReferenceForm" style="color: #409EFF;cursor: pointer">
          {{value || '⠀' }}
        </span>
      </div>
    </template>

    <el-form-item v-else :required="context.authentication.isRequired(element)">
      <template #label>
      <span :style="{color:'#344262','font-weight':context.form.setting.style.PC.title}">
        {{ element.label }}
        <form-element-tooltip :element="element"></form-element-tooltip>
      </span>
      </template>
      <el-autocomplete v-if="type==='fill'" v-model="value" placeholder="请选择" style="width: 100%" :teleported="true"
                       :fetch-suggestions="querySearch" @select="handleSelect" clearable @clear="data[element.id]=''">
        <template #suffix>
          <el-button v-prevent-re-click icon="Connection" style="font-size: 16px" link
                     @click.stop="handleClick"></el-button>
        </template>
        <template #default="{ item }">
          <div class="value">{{ item.value }}</div>
        </template>
      </el-autocomplete>
      <div v-else-if="type==='detail'" class="design-detail">
        <span v-if="value && value==='表单已删除'" style="color: #E57470">{{ value }}</span>
        <span v-else @click="handleClickReferenceForm" style="color: #409EFF;cursor: pointer"> {{
            value || '⠀'
          }} </span>
      </div>
    </el-form-item>

    <form-data-select ref="formDataSelect" @callback="handleSelect"></form-data-select>
    <app-form-view ref="appFormView"></app-form-view>
  </div>


</template>

<script>

import {mapStores} from "pinia";
import {useFlowStore} from "@/stores/flow";
import {useFormStore} from "@/stores/form";
import FormDataSelect from "@/components/common/formDataSelect.vue";
import AppFormView from "@/components/app-form/app-form-view.vue";
import FormElementTooltip from "@/components/common/formElementTooltip.vue";

export default {
  components: {FormElementTooltip, AppFormView, FormDataSelect},
  props: {
    context: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      data: {},
      type: '',
      value: '',
      selectSubFormIndex: '',
    }
  },
  computed: {
    ...mapStores(useFlowStore, useFormStore)
  },
  created() {
    this.data = this.context.data;
    this.type = this.flowStore.typeOfElement(this.element, this.context);

    if (this.data[this.element.id]) {
      this.findTitleById(this.data[this.element.id])
    }
  },
  mounted() {
    this.$watch(`data.${this.element.id}`, (newVal, oldVal) => {
      this.findTitleById(newVal);
      this.formStore.findById(this.element.attrs.form_id, newVal).then(async res => {
        await this.formStore.referenceFormFill(res.data, this.data, this.element, this.context.form, this.selectSubFormIndex)
      })
    })

  },
  methods: {
    querySearch(queryString, cb) {
      if (this.element.attrs.form_id && (!this.element.attrs.fillRole.mode || this.element.attrs.fillRole.mode === 'default')) {
        this.formStore.referenceForm(this.element.attrs.form_id, queryString).then(res => {
          res.data.forEach(item => item.value = Object.keys(item).filter(filter => filter !== 'id').map(mapper => item[mapper]).join(" "))
          cb(res.data);
        })
      } else {
        cb([])
      }

    },
    handleSelect(item, selectSubFormIndex) {
      this.selectSubFormIndex = selectSubFormIndex;
      this.data[this.element.id] = item.id;
    },
    async findTitleById(data_id) {
      this.value = (await this.formStore.findTitleById(this.element.attrs.form_id, data_id)).data;
    },
    handleClick() {
      this.$refs.formDataSelect.call(this.element.attrs.form_id, this.data[this.element.id], this.element.attrs.fillRole.mode)
    },
    handleClickReferenceForm() {


      if (this.element.attrs.form_id && this.data[this.element.id]) {
        this.$refs.appFormView.call(this.element.attrs.form_id, this.data[this.element.id])
      }

    }

  }
}

</script>

<style scoped lang="scss">

</style>