import {defineStore} from "pinia";
import {useUserStore} from "@/stores/user";


export const useFormPermissionStore = defineStore('formPermission', {

    state: () => {
        return {
            _default: [{
                name: '默认权限',
                description: '当前表单数据默认权限',
                members: [],
                isAll: true,
                dataRange: '全部数据',
                operations: ['查看', '编辑', '提交', '删除', '变更记录', '评论', '打印']
            }]
        }
    },
    actions: {
        init(form) {
            let userStore = useUserStore();

            let result = {
                dataRange: [],
                operations: [],
                isDelete() {
                    return this.operations.includes("删除")
                },
                isEdit() {
                    return this.operations.includes('编辑');
                },
                isView() {
                    return this.operations.includes('查看');
                },
                isSubmit() {
                    return this.operations.includes('提交');
                },
                isComment() {
                    return this.operations.includes("评论")
                },
                isPrint() {
                    return this.operations.includes("打印")
                },
                isChange() {
                    return this.operations.includes("变更记录")
                }

            }

            let permissions = form.permissions ? form.permissions : this._default;
            permissions.filter(permission => {
                let members = permission.members;
                return userStore.isRoot() || permission.isAll || members.some(member => {
                    if (member.type === 'user') {
                        return userStore.getUser.id === member.id;
                    } else if (member.type === 'dept') {
                        return userStore.isDept(member.id);
                    } else if (member.type === 'role') {
                        return userStore.isRole(member.id);
                    }
                })


            }).forEach(permission => {
                result.dataRange.push(permission.dataRange);
                result.operations.push(...permission.operations);
            })

            result.dataRange = Array.from(new Set(result.dataRange))
            result.operations = Array.from(new Set(result.operations))

            return result;
        }
    }


});