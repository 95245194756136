<template>

  <el-dialog v-model="dialogVisible" fullscreen :close-on-press-escape="closeOnPressEscape"
             style="--el-bg-color:rgba(14,14,34,0.5);--el-dialog-padding-primary:0;--el-color-info:white"
             :close-on-click-modal="closeOnClickModal" @close="()=>{
               $emit('close')
             }">
    <div style="margin-top: 40px;min-height: calc(100vh - 60px);">
      <slot></slot>
    </div>

  </el-dialog>

</template>

<script>
export default {
  emits:['close'],
  props:{
    closeOnPressEscape:{
      type:Boolean,
      default:false
    },
    closeOnClickModal:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      dialogVisible: false
    }
  },
  methods: {
    call() {
      this.dialogVisible = true;
    },
    close(){
      this.dialogVisible = false;
    }
  }

}

</script>

<style scoped lang="scss">

:deep(.el-dialog__body){
  background: white;
}

</style>