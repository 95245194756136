<template>

  <div>

    <el-table ref="multipleTable" size="small" :data="columns" stripe :height="isFullscreen?'66vh':'35vh'"
              @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="字段名称" prop="name" align="center">
        <template #header>
          字段名称 <span style="color:#409EFF;font-weight: normal"> ({{ value.length }}/{{ columns.length }})</span>
        </template>
      </el-table-column>
      <el-table-column label="来源" prop="nodeName" align="center">
        <template #default="scope">
          <span style="color:#409EFF;font-weight: normal"> {{ scope.row.nodeName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="来源类型" prop="nodeType" align="center">
        <template #default="scope">
          <span style="color:#AAAAAA;font-weight: normal"> {{ scope.row.nodeType }}</span>
        </template>
      </el-table-column>
      <el-table-column label="别名" prop="alias" align="center">
        <template #default="scope">
          <el-input v-model="scope.row.alias" size="small" style="width: 80%" @change="callback"></el-input>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    isFullscreen: {
      type: Boolean,
      default: () => false
    },
    columns: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      value: [],
      multipleSelection: []
    }
  },
  created() {

  },
  mounted() {
    this.value = this.deepClone(this.modelValue);
    let columns = this.columns;
    if (!this.value || this.value.length === 0) {
      let array = []
      this.columns.forEach(column => {
        if (!array.some(item => item.name === column.name && item.nodeName !== column.nodeName))
          array.push(column)
      });

      this.toggleSelection(array)
    } else {
      let array = []
      this.value.forEach(item => {
        for (let i = 0; i < this.columns.length; i++) {
          if (item.name === this.columns[i].name && item.id === this.columns[i].id) {
            columns[i].alias = item.alias;
            array.push(this.columns[i]);
          }
        }
      });
      this.toggleSelection(array)
    }
  },
  methods: {
    test() {
      console.log(JSON.stringify(this.value, null, 4))
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => this.$refs.multipleTable.toggleRowSelection(row));
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.callback();
    },
    callback() {
      let list = []
      this.multipleSelection.forEach(item => {
        let {id, name, alias} = item;
        list.push({id, name, alias})
      })
      this.$emit('update:modelValue', list)
    }
  }
}
</script>

<style scoped>

</style>