<template>

  <el-dialog v-model="dialogVisible" title="过滤" :close-on-click-modal="false" width="920px"
             style="border-radius: 10px"
             append-to-body>

    <div v-if="inputs.length>0">
      <el-table v-if="node" 
                :data="node.attrs.items.filter(filter=>keyword?(filter.name.indexOf(keyword)>=0):true)"
                :height="isFullscreen?'76vh':'32vh'"  :header-cell-style="{color:'#181A1D'}">
        <el-table-column type="index" width="50" align="center"></el-table-column>
        <el-table-column label="字段名" align="center">
          <template #header>
            扩展字段
            <el-input v-model="keyword"  placeholder="输入关键字搜索" style="width: 60%;margin-left: 20px;"/>
          </template>
          <template #default="scope">
            <el-autocomplete v-model="scope.row.name" :fetch-suggestions="querySearch" placeholder="字段名"></el-autocomplete>
          </template>
        </el-table-column>
        <el-table-column label="值" align="center">
          <template #default="scope">
            <el-input v-model="scope.row.value" >
              <template #append>
                <!-- 公式编辑-->
                <el-popover :ref="'popover'+scope.$index" placement="bottom" width="840" trigger="click" style="--el-popover-border-radius:10px">
                  <calc-code :ref="'calcCode'+scope.$index" :value="scope.row.value||''" :columns="columns" :functions="functions"></calc-code>
                  <div style="text-align: right; margin: 0">
                    <el-button  text @click="$refs['popover'+scope.$index].hide()">取消
                    </el-button>
                    <el-button type="primary"  title="编辑" @click="scope.row.value= $refs['calcCode'+scope.$index].getText();$refs['popover'+scope.$index].hide()">
                      确定
                    </el-button>
                  </div>
                  <template #reference>
                    <el-button style="color: #55aaff;" text icon="Basketball"></el-button>
                  </template>
                </el-popover>
              </template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="scope">
            <el-button icon="Remove" link style="color: #ff557f;" title="删除" @click="delCalc(scope.$index)"></el-button>
            <el-button icon="Refresh" link title="重置" @click="refresh(scope.$index)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-button icon="CirclePlus" type="primary" style="margin-left: 10px;margin-top: 20px"
                 @click="addCalc" >
        添加扩展字段
      </el-button>
    </div>
    <error ref="error"></error>

  </el-dialog>

</template>

<script>
//禁用页面回退功能,防止用户页面回退,导致信息错误
history.pushState(null, null, document.URL);
window.addEventListener('popstate', function () {
  history.pushState(null, null, document.URL);
});
import {mapStores} from "pinia";
import {useEtlStore} from "@/stores/etl";
import CalcCode from './CalcCode.vue'
import error from "@/components/etl/node/error";

export default {
  computed:{
    ...mapStores(useEtlStore)
  },
  components: {
    CalcCode, error
  },
  props: {
    isFullscreen: {
      type: Boolean,
      default: () => false,
    }
  },
  data() {
    return {
      dialogVisible:false,
      node: null,
      data: null,
      keyword: '',
      columns: [],
      functions: [],
      inputs: [],
    }
  },
  methods: {
    call(node, data) {
      this.node = node;
      this.data = data;
      this.keyword = '';
      this.getNodeColumns()
      this.getScripts()
      this.dialogVisible = true;
    },
    querySearch(queryString, cb) {
      let restaurants = this.columns.map(mapper => {
        return {value: mapper.label}
      });
      let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    getScripts() {
      this.etlStore.getScripts().then(res => this.functions = res.data)
    },
    getNodeColumns() {
      this.inputs = this.data.lineList.filter(filter => filter.to === this.node.id);

      if (this.inputs.length !== 1) {
        this.$nextTick(() => this.$refs.error.show('没有连接输入源，组件无法正常运行'))
      } else {
        this.etlStore.getNodeColumns(this.inputs[0].from, this.data).then(res => {
          if (res.code === 200) {
            this.columns = res.data.map(mapper => {
              return {
                label: mapper.name,
                nodeName: mapper.nodeName,
                nodeType: mapper.nodeType
              }
            })
          }
        })
      }
    },
    addCalc() {
      this.node.attrs.items.push({
        name: '',
        value: '',
      })
    },
    delCalc(index) {
      if (this.node.attrs.items.length > 1) {
        this.node.attrs.items.splice(index, 1)
      }
    },
    refresh(index) {
      let obj = this.node.attrs.items[index]
      obj.name = '';
      obj.value = ''
    }
  }
}
</script>

<style scoped>
:deep( .el-table thead) {
  color: #1F1C2C;
}


:deep(.el-popover){
  --el-popover-border-radius:10px
}

</style>
