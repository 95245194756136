<template>

  <div class="tr"  v-if="type==='default'&& !parent">
    <div class="th">{{element.label}}</div>
    <div class="td">{{depts.map(mapper=>mapper.name).join(',')}}</div>
  </div>
  <template v-else-if="type==='excel'">
    <div class="th">{{ element.label }}</div>
    <div class="td">
      {{depts.map(mapper=>mapper.name).join(',')}}
    </div>
  </template>
  <span v-else>{{depts.map(mapper=>mapper.name).join(',')}}</span>

</template>

<script>

import {mapStores} from "pinia";
import {useDeptStore} from "@/stores/dept";

export default {
  components: {},
  computed:{
    ...mapStores(useDeptStore)
  },
  props: {
    form:{
      type: Object,
    },
    data:{
      type:Object,
    },
    element:{
      type:Object
    },
    parent:{
      type:Object
    },
    type: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {
      depts:[],
    }
  },
  created() {


    if(this.data[this.element.id]){

      if(typeof  this.data[this.element.id] === 'string'){
        this.deptStore.findById(this.data[this.element.id]).then(res=>{
          this.depts  = [res.data];
        })
      }else{
        this.deptStore.findByIds(this.data[this.element.id]).then(res=>{
          this.depts  = res.data;
        })
      }


    }

  }
}

</script>

<style scoped lang="scss">

</style>