<template>

  <el-form label-width="80px" label-position="left">
    <el-form-item label="应用">
      <el-select v-model="attrs.app" @change="handleAppChange">
        <el-option v-for="(item,index) in apps" :key="index" :label="item.name" :value="item.id"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="表单">
      <el-select v-model="attrs.form" default-first-option filterable @change="handleFormChange">
        <el-option v-for="(item,index) in forms" :key="index" :label="item.name" :value="item.id"></el-option>
      </el-select>
    </el-form-item>
  </el-form>

  <el-table ref="table" :data="elements" row-key="id" border
            style="width: 100%;background: white;--el-table-tr-bg-color:white;--el-table-header-bg-color:white"
            :header-cell-style="{color:'black'}"
            size="small" :tree-props="{checkStrictly:false}"
            @selection-change="handleSelectionChange" max-height="calc(100vh - 360px)">
    <el-table-column type="selection" width="35px" prop="select" :reserve-selection="true" :selectable="(row)=>{
     return row.tag!=='id';
    }"></el-table-column>
    <el-table-column label="名称" prop="name">
      <template #default="scope">
        <span>{{ scope.row.name }}</span>
        <el-tag style="float: right;font-size: 12px" size="small" effect="plain">{{ scope.row.tagName }}</el-tag>
      </template>
    </el-table-column>
    <el-table-column label="别名" width="120px">
      <template #default="scope">
        <el-input v-model="scope.row.alias" size="small" :key="scope.row.id" @change="(val)=>{
          let match = attrs.keepColumns.find(find => find.id === scope.row.id);
          match.alias = val;
        }"></el-input>
      </template>
    </el-table-column>

  </el-table>

</template>

<script>
import {mapStores} from "pinia";
import {useEtlStore} from "@/stores/etl";
import {useFormStore} from "@/stores/form";

export default {
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    ...mapStores(useEtlStore, useFormStore)
  },
  data() {
    return {
      attrs: {
        app: '',
        form: ''
      },
      apps: [],
      forms: [],
      elements: [],
      multipleSelection: []
    }
  },
  async created() {
    this.attrs = this.element.attrs;
    this.queryApps();
    if (this.attrs.app) {
      this.queryForms(this.attrs.app)
    }

    if (this.attrs.form) {
      await this.queryElements(this.attrs.form)
      this.select();
    }
  },
  methods: {
    queryApps() {
      this.etlStore.listApp().then(res => {
        this.apps = res.data;
      })
    },
    handleAppChange(val) {
      this.attrs.app = val;
      this.attrs.form = '';
      this.attrs.keepColumns = [];
      this.queryForms(val)
    },
    queryForms(val) {
      this.etlStore.listForm(val).then(res => {
        this.forms = res.data;
      })
    },
    handleFormChange(val) {
      this.attrs.form = val;
      this.attrs.keepColumns = [];
      this.$refs.table.clearSelection()
      this.queryElements(val);
    },
    async queryElements(val) {
      await this.etlStore.formElements(val).then(res => {
        this.elements = this.etlStore.columns(res.data);
        this.etlStore.alias(this.elements, this.attrs.keepColumns);
        this.$nextTick(()=>{
          this.$refs.table.toggleRowSelection(this.elements.find((find)=>find.id==='id'), true, true)
        })
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
      this.save()
    },
    save() {
      this.$nextTick(() => {
        this.attrs.keepColumns = this.multipleSelection.map(mapper => {
          return {
            id: mapper.id,
            parent: mapper.parent,
            name: mapper.name,
            alias: mapper.alias,
          }
        });
      })
    },
    select() {
      let rows = this.attrs.keepColumns;
      let ids = rows.map(mapper => mapper.id);
      this.elements.flatMap(mapper => {
        if (mapper.children) {
          return [mapper, ...mapper.children]
        } else {
          return [mapper]
        }
      }).forEach((row) => {
        if (row.tag === 'id') {
          this.$refs.table.toggleRowSelection(row, true, true)
        } else {
          let expand = ids.includes(row.id);
          this.$refs.table.toggleRowSelection(row, expand)

          if (expand && row.parent) {
            let parent = this.elements.find(find => find.id === row.parent);
            this.$refs.table.toggleRowExpansion(parent, true);
          }
        }

      });
    }

  }
}

</script>


<style scoped lang="scss">

.el-select {
  width: 220px;
}

</style>