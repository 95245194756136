<template>

  <el-form-item>
    <template #label>
      <span
          :style="{display: 'flex','align-items': 'center',color:'#344262','font-weight':context.formStyleSetting.PC.title}">
        {{ element.label }}
        <form-element-tooltip :element="element"></form-element-tooltip>
      </span>
    </template>
    <div style="overflow-x: auto;">
    <draggable :list="element.children" :group="{ name:'design',pull:true,put:true}"
               ghost-class="ghostClass" chosen-class="chosenClass" drag-class="dragClass"
               :force-fallback="true" class="subForm" name="subForm"
               animation="300"
               item-key="id" :sort="true" @change="onChange" :move="formStore.onDraggableMove">

      <template #header>
        <div style="flex:0.5;border-right:1px solid #D5D7DF">
          <el-form-item label="序号">
            1
          </el-form-item>
        </div>
      </template>
      <template #item="scope">
        <div style="min-width: 240px;position: relative;border-right: 1px solid #D5D7DF;">
          <design-wrapper :context="context" :element="scope.element" :parent="element.children">
            <component :is="`design${scope.element.tag.charAt(0).toUpperCase() + scope.element.tag.slice(1)}`"
                       :context="{...context,parent:element}" :element="scope.element"
                       :parent="element.children"></component>
          </design-wrapper>
        </div>
      </template>
      <template #footer>
        <div style="flex: 1.2;color: #344262;text-align: center;padding: 30px">
          从左侧拖拽来添加字段
        </div>
      </template>
    </draggable>
    </div>
  </el-form-item>


</template>

<script>
import draggable from "vuedraggable";
import designWrapper from "@/components/design-form/items/designWrapper.vue";
import {mapStores} from "pinia";
import {useFormStore} from "@/stores/form";
import FormElementTooltip from "@/components/common/formElementTooltip.vue";

export default {
  computed: {
    ...mapStores(useFormStore)
  },
  components: {
    FormElementTooltip,
    draggable, designWrapper
  },
  props: {
    context: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    },
    parent: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      data: [{}]
    }
  },
  watch: {
    "element.children.length": {
      handler(newValue, oldValue) {

        let element = this.element;

        if( element.attrs===undefined){
          element.attrs ={
            title:[]
          }
        }

        element.attrs.title = this.element.attrs.title.filter(filter => this.formStore.columns().some(item => item.id === filter) || this.element.children.some(child => child.id === filter))
      },
      deep: true,
      immediate: false
    }
  },
  methods: {
    onChange(original) {
      if (original.added) {
        this.formStore.onDeleteElementCauseInfluence(original.added.element.id)
      }
    },
  }
}

</script>

<style scoped lang="scss">

.subForm {
  margin-top: 10px;
  border: 1px solid #D5D7DF;
  border-radius: 4px;
  min-height: 86px;
  min-width: 280px;
  width: fit-content;
  white-space: nowrap;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.subForm > * {
  flex: 1;
}

.designWrapper {
  border-bottom: none;
  padding: 0 0 10px 0;
}

.designWrapper-on {
  border: 1px dashed #3581F7;
}


:deep( .subForm .el-form-item__label) {
  font-size: 12px;
  background-color: #EAEFF4;
  padding: 10px;
  font-weight: bold;
  color: #364260;
  width: 100%;
}

:deep( .el-form-item__content) {
  padding: 0 20px 2px 20px;
  align-content: center;
}

:deep( .designWrapper-button) {
  text-align: right;
  position: absolute;
  right: 10px;
  top: 0;
  margin-top: 0;
  display: none;
}


/* 拖动和放置 */
:deep(.ghostClass) {
  background-color: #DCEBFD !important;
  width: 200px;
  height: 74px !important;
  cursor: default !important;
  color: rgba(0, 0, 0, 0) !important;
}

:deep(.chosenClass) {
  background-color: #DCEBFD !important;
  border: none !important;

  .designWrapper {
    border: none !important;
  }
}

</style>