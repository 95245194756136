<template>

  <el-drawer v-model="drawerVisible" direction="rtl" :size="fullScreen?'100%': '65%'" :append-to-body="true"
             :destroy-on-close="true" :close-on-click-modal="false" :modal="false"
             style="background-color: #F5F7FA">

    <template #header="{  titleId, titleClass }">
      <span :id="titleId" :class="titleClass"
            style="font-size: 18px;color: #344262;font-weight: bold">{{
          showSelectFillSubForm ? '选择填充数据' : '选择关联表单'
        }}</span>
      <el-button icon="FullScreen" link type="info" style="margin-right: 10px"
                 @click="fullScreen=!fullScreen"></el-button>
    </template>


    <template #default>

      <div v-show="!showSelectFillSubForm"
           style="background-color: white;padding: 20px 20px 20px 20px;min-height: 100%; border-radius: 8px;">

        <div style="color: #303133;cursor: pointer;font-size: 18px;font-weight: bold;margin-bottom: 20px">
          {{ form?.name || '关联表单已删除' }}
        </div>
        <el-input v-if="form" suffix-icon="Search" v-model="keyword" style="width: 300px" @input="queryList(1)"
                  placeholder="数据标题模糊搜索"></el-input>


        <el-form v-if="form && filters.length>0"
                 style="border: 1px dashed #D5D7DF;margin: 40px 0 0 0;padding:20px 0 10px 0"
                 size="small" label-position="right" label-suffix=" -" @keyup.enter="queryList(1)">
          <el-row>
            <el-col v-for="(filter,index) in filters" :key="index" :span="8">
              <simple-condition :ref="`c-${filter.id}`"
                                :column="columns.filter(item=>item.id===filter.id)[0]"
                                :filter="filter"
                                @change="queryList(1)">
              </simple-condition>
            </el-col>
          </el-row>
        </el-form>

        <el-table v-if="form" :data="list" tooltip-effect="dark" style="width: 100%;border-radius: 6px;margin-top: 20px"
                  :max-height="getTableHeight" :header-cell-style="{color:'#344262','background-color':'#F2F7F9'}"
                  element-loading-background="rgb(255,255,255,0.4)" v-loading="loadingTable" empty-text="   " border
                  stripe @selection-change="handleSelectionChange">
          <el-table-column v-if="multiple" type="selection" width="55"></el-table-column>
          <el-table-column v-if="!multiple" width="55" fixed="left" align="center">
            <template #default="scope">
              <el-radio :value="scope.row.id" :key="scope.row.id" v-model="select"
                        @click="handleRowSelect(scope.row)"></el-radio>
            </template>
          </el-table-column>
          <el-table-column label="数据标题" fixed="left" width="200">
            <template #default="scope">
              <span class="data-title">{{ scope.row.title }}</span>
            </template>
          </el-table-column>
            <el-table-column v-for="item in filteredColumns" :key="item.id" :label="item.label.toUpperCase()" align="center" >
              <template v-slot:default="scope">
                <column-show :key="item.id+new Date()" :column="item" :value="formStore.getValue(scope.row,item.id)" container="table"></column-show>
              </template>
            </el-table-column>
          <el-table-column></el-table-column>
        </el-table>
        <el-pagination v-if="form" style="margin-top: 24px;float: right;" small v-model:current-page="page.currentPage"
                       v-model:page-size="page.pageSize" :page-sizes="[10, 20, 30, 40,50]" :total="page.count"
                       background @change="queryList()" layout=" total,prev,pager, next,jumper,sizes"/>
      </div>
      <div v-if="showSelectFillSubForm && select"
           style="background-color: white;padding: 20px 20px 20px 20px;min-height: 100%; border-radius: 8px;">
        <el-breadcrumb separator-icon="ArrowRight">
          <el-breadcrumb-item @click="selectSubForm=null;select=null;showSelectFillSubForm=false">
            <b style="color: #409EFF;cursor: pointer">{{ form.name }}</b>
          </el-breadcrumb-item>
          <el-breadcrumb-item>{{ columns.find(column => column.id === this.mode)?.label || '' }}</el-breadcrumb-item>
        </el-breadcrumb>
        <el-table :data="list.find(find => find.id === this.select)[this.mode]" tooltip-effect="dark"
                  style="width: 100%;border-radius: 6px;margin-top: 20px"
                  :max-height="getTableHeight" :header-cell-style="{color:'#344262','background-color':'#F2F7F9'}"
                  element-loading-background="rgb(255,255,255,0.4)" v-loading="loadingTable" empty-text="   " border
                  stripe>
          <el-table-column width="55" fixed="left" align="center">
            <template #default="scope">
              <el-radio :value="scope.$index" :key="scope.$index" v-model="selectSubForm"></el-radio>
            </template>
          </el-table-column>
          <el-table-column v-for="(column,index) in columns.find(filter=>filter.id===this.mode).children" :key="index"
                           :label="column.label" align="center">
            <template v-slot:default="scope">
              <column-show  :key="column.id+new Date()" :column="column" :value="formStore.getValue(scope.row,column.id)" container="table"></column-show>
            </template>

          </el-table-column>
          <el-table-column></el-table-column>
        </el-table>
      </div>

    </template>

    <template #footer>
      <div style="text-align: center">
        <el-button @click="drawerVisible=false">取 消</el-button>
        <el-button type="primary" @click="submit"
                   :disabled="mode && mode!=='default' && !Number.isInteger(selectSubForm) ">确 定
        </el-button>
      </div>
    </template>

  </el-drawer>


</template>


<script>
import {mapStores} from "pinia";
import {useFormStore} from "@/stores/form";
import {useMenuStore} from "@/stores/menu";

export default {
  components: {},
  emits: ['callback'],
  props: {
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      select: '',
      selectSubForm: null,
      mode: null,
      drawerVisible: false,
      fullScreen: false,
      form: null,
      form_id: null,
      data_id: null,
      loadingTable: false,
      columns: [],
      fields: [],
      filters: [],
      list: [],
      keyword: '',
      showSelectFillSubForm: false,
      page: {
        currentPage: 1,
        pageSize: 10,
        count: 0,
      },
    }
  },
  computed: {
    ...mapStores(useFormStore, useMenuStore),
    filteredColumns() {
      return this.fields.map(item => this.columns.find(filter => filter.id === item));
    },
    getTableHeight() {

      let height = 320;

      if (this.filters && this.filters.length > 0) {
        let row = parseInt(this.filters.length % 3 === 0 ? this.filters.length / 3 : this.filters.length / 3 + 1);
        height +=   68 + row * 42
      }

      return   `calc(100vh - ${height}px)`;
    }
  },
  created() {


  },
  methods: {
    async call(form_id, data_id, mode) {
      this.form_id = form_id;
      this.data_id = data_id;
      this.mode = mode;
      this.select = data_id;
      this.showSelectFillSubForm = false;
      this.selectSubForm = null;

      if (!this.form_id) {
        this.message('关联表单未设置', 'info')
        return
      }


      await this.menuStore.findById(this.form_id).then(res => {
        this.form = res.data;
        if (!this.form) {
          this.message('关联表单不存在', 'error')
          this.$nextTick(() => this.drawerVisible = false)
          return;
        }
        this.columns = this.formStore.columns(this.form);
        this.fields = !this.form.setting.list.fields || this.form.setting.list.fields.length === 0 ? this.columns.map(mapper => mapper.id) : this.form.setting.list.fields;
        this.filters = !this.form.setting.list.filters ? [] : this.form.setting.list.filters;
        if (this.mode && this.mode !== 'default' && !this.fields.includes(this.mode)) {
          this.fields.push(this.mode);
        }
      })

      this.drawerVisible = true;
      this.$nextTick(() => {
        this.queryList(1)
      })
    },
    queryList(currentPage) {
      this.loadingTable = true;
      this.page.currentPage = currentPage || this.page.currentPage;

      let conditions = [];
      if (this.filters.length > 0) {
        this.filters.forEach(filter => {
          conditions.push(this.$refs[`c-${filter.id}`][0].getCondition());
        })
      }


      this.formStore.list(this.form_id, this.page.currentPage, this.page.pageSize, "[]",JSON.stringify(conditions), this.keyword).then(res => {
        if(res.code === 200){
          this.list = res.data.list;
          this.page = res.data.page;
        }

        this.loadingTable = false;
      })
    },
    handleRowSelect(row) {
      this.$nextTick(() => {
        if (this.mode && this.mode !== 'default') {
          this.showSelectFillSubForm = true;
        }
      })
    },
    submit() {
      if (this.multiple) {
        let data = this.multipleSelection.map(mapper => mapper.id);
        this.$emit('callback', data);
        this.drawerVisible = false;
      } else {
        let data = this.list.filter(filter => filter.id === this.select)[0];
        this.$emit('callback', data, this.selectSubForm);
        this.drawerVisible = false;
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  }


}

</script>


<style scoped lang="scss">

</style>