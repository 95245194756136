<template>


  <template v-for="(child,index) in element.list1" :key="index">
    <component :is="`design${child.tag.charAt(0).toUpperCase() + child.tag.slice(1)}Print`"
             :form="form"  :data="data" :element="child" ></component>
  </template>
  <template v-for="(child,index) in element.list2" :key="index">
    <component :is="`design${child.tag.charAt(0).toUpperCase() + child.tag.slice(1)}Print`"
               :form="form"  :data="data" :element="child" ></component>
  </template>
  <template v-for="(child,index) in element.list3" :key="index">
    <component :is="`design${child.tag.charAt(0).toUpperCase() + child.tag.slice(1)}Print`"
               :form="form"  :data="data" :element="child" ></component>
  </template>
  <template v-for="(child,index) in element.list4" :key="index">
    <component :is="`design${child.tag.charAt(0).toUpperCase() + child.tag.slice(1)}Print`"
               :form="form"  :data="data" :element="child" ></component>
  </template>


</template>

<script>


export default {
  components: {},
  props: {
    data:{
      type:Object,
    },
    element:{
      type:Object
    },
    parent:{
      type:Object
    },
    form:{
      type:Object
    }
  },
  data() {
    return {}
  },
  created() {
  }
}

</script>

<style scoped lang="scss">

</style>