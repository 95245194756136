<template>
  <div>

  <el-alert :title="element.attrs.title" :type="element.attrs.type?element.attrs.type:'info'" :closable="false" style="margin: 20px 0" >
    <template #default>
      <div  v-html="element.value.replaceAll('\n','<br/>')"></div>
    </template>
  </el-alert>
  </div>
</template>


<script>

export default {
  props: {
    context:{
      type:Object,
      default:()=>{}
    },
    element:{
      type:Object,
      default:()=>{}
    }
  },
  data() {
    return {

    }
  },
  created() {

  }
}

</script>

<style scoped lang="scss">


</style>