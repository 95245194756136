const cs = [{
    label: '大于',
    value: 'GT',
}, {
    label: '大于等于',
    value: 'GT_EQUALS',
}, {
    label: '小于',
    value: 'LT',
}, {
    label: '小于等于',
    value: 'LT_EQUALS',
}, {
    label: '在列表',
    value: 'IN',
}, {
    label: '不在列表',
    value: 'NOT_IN',
}, {
    label: '等于',
    value: 'EQUALS',
}, {
    label: '等于字段',
    value: 'EQUALS_CC',
},{
    label: '不等于',
    value: 'NO_EQUALS',
},{
    label: '不等于字段',
    value: 'NO_EQUALS_CC',
}, {
    label: '包含',
    value: 'CONTAINS',
}, {
    label: '不包含',
    value: 'NO_CONTAINS',
}, {
    label: '开始以',
    value: 'START_WITH',
}, {
    label: '开始不是以',
    value: 'NO_START_WITH',
}, {
    label: '结束以',
    value: 'END_WITH',
}, {
    label: '结束不是以',
    value: 'NO_END_WITH',
}, {
    label: '是 NULL',
    value: 'IS_NULL',
}, {
    label: '不是 NULL',
    value: 'IS_NOT_NULL',
}, {
    label: '是空的',
    value: 'IS_EMPTY',
}, {
    label: '不是空的',
    value: 'IS_NOT_EMPTY',
}, {
    label: '介于',
    value: 'BETWEEN',
}, {
    label: '不介于',
    value: 'NOT_BETWEEN',
}
]

export function getCs() {
    return cs;
}