<template>

  <div class="tr" v-if="type==='default'&& !parent">
    <div class="th">{{ element.label }}</div>
    <div class="td">
      <span v-for="(item,index) in data[element.id]" style="width: 100px;height: auto" :key="index"
      > {{ item.file_name }} </span>
    </div>
  </div>
  <template v-else-if="type==='excel'">
    <div class="th">{{ element.label }}</div>
    <div class="td">
      <span v-for="(item,index) in data[element.id]" style="width: 100px;height: auto" :key="index"
      > {{ item.file_name }} </span>
    </div>
  </template>
  <div v-else>
    <span v-for="(item,index) in data[element.id]" style="width: 100px;height: auto" :key="index">
      {{ item.file_name }}
    </span>
  </div>

</template>

<script>

import {mapStores} from "pinia";
import {useAppStore} from "@/stores/app";

export default {
  components: {},
  computed: {
    ...mapStores(useAppStore)
  },
  props: {
    form:{
      type: Object,
    },
    data: {
      type: Object,
    },
    element: {
      type: Object
    },
    parent: {
      type: Object
    },
    type: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {}
  },
  created() {
  }
}

</script>

<style scoped lang="scss">

</style>
<script setup lang="ts">
</script>