<template>
  <router-view v-if="!loading" />
  <div v-else style="height: 100vh;background-color: #EEF5FB" v-loading="loading"
       :element-loading-text="loadingText" element-loading-background="transparent"></div>
</template>

<script>
import {mapStores} from "pinia";
import {useCompanyStore} from "@/stores/company";
import {useUserStore} from "@/stores/user";


export default {

  computed: {
    ...mapStores(useCompanyStore,useUserStore)
  },
  data(){
    return {
      loadingText:'Nocode Loading...',
      loading:false,
    }
  },
  async created() {
    this.loading = true;
    await this.userStore.initUser();
    if(this.userStore.token){
      await this.companyStore.init();
    }
    window.addEventListener('keydown', this.handleKeydown);
    this.loading = false;
  },
  methods:{
    handleKeydown(event) {
      // 检查是否按下 Backspace 键
      if (event.key === 'Backspace') {
        // 获取事件的目标元素
        const target = event.target;

        // 判断目标元素是否为输入框、textarea 或 contenteditable 元素
        const isInput = target.tagName === 'INPUT';
        const isTextarea = target.tagName === 'TEXTAREA';
        const isContentEditable = target.getAttribute('contenteditable') === 'true';

        // 如果不是上述元素之一，则阻止默认行为
        if (!(isInput || isTextarea || isContentEditable)) {
          event.preventDefault();
        }
      }
    }
  }

}


</script>

<style>

#app {
  font-size: 12px;
}

.el-icon-loading {
  color: mediumvioletred;
}

.mx-context-menu-item .label {
  cursor: default;
  margin-left: 4px;
}


.el-scrollbar {
  .el-scrollbar__bar.is-horizontal {
    height: 10px;
  }

  .el-scrollbar__bar.is-horizontal .el-scrollbar__thumb {
    background: #171515;
    cursor: default;
    height: 6px;
  }
}



i {
  font-size: 14px;
}

.el-table .cell {
  font-size: 13px !important;
}

/** remove qq.map log */
a .csssprite {
  display: none;
}

div[draggable="false"][onresize="return;"] {
  display: none;
}

/** remove ckEditor log */
div .ck-powered-by {
  display: none;
}

.el-drawer__header {
  margin-bottom: 0 !important;
}

.el-drawer__title {
  color: #344262;
}


.el-divider--horizontal {
  margin: 10px 0 20px 0;
}

.danger{
  color: #F56C6C;
}

.el-form-item__label{
  width: 100%;
}

.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

</style>
