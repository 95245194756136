<template>


  <template v-if="type==='default'">
    <div class="tr">
      <div class="th" style="text-align: center">{{ element.label }}</div>
    </div>
    <div class="tr">
      <div class="table">
        <div class="tr">
          <div class="th" v-for="(child,index) in element.children" :key="index">
            {{ child.label }}
          </div>
        </div>
        <div class="tr" v-for="(child,index) in data[element.id]" :key="index">
          <div class="td" v-for="(childElement,childElementIndex) in element.children" :key="childElementIndex">
            <component :is="`design${childElement.tag.charAt(0).toUpperCase() + childElement.tag.slice(1)}Print`"
                       :form="form" :data="child" :element="childElement" :parent="element"></component>
          </div>
        </div>
      </div>
    </div>
  </template>

  <template v-else>
    <div class="tr">
      <div class="th" style="text-align: center">{{ element.label }}</div>
    </div>
    <div class="tr">
      <div class="table">
        <div class="tr">
          <div class="th" v-for="(child,index) in element.children" :key="index">
            {{ child.label }}
          </div>
        </div>
        <div class="tr" v-for="(child,index) in data[element.id]" :key="index">
          <div class="td" v-for="(childElement,childElementIndex) in element.children" :key="childElementIndex">
            <component :is="`design${childElement.tag.charAt(0).toUpperCase() + childElement.tag.slice(1)}Print`"
                       :form="form" :data="child" :element="childElement" :parent="element"></component>
          </div>
        </div>
      </div>
    </div>

  </template>


</template>

<script>


export default {
  components: {},
  props: {
    form: {
      type: Object,
    },
    data: {
      type: Object,
    },
    element: {
      type: Object
    },
    parent: {
      type: Object
    },
    type: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {}
  },
  created() {
  }
}

</script>

<style scoped lang="scss">

</style>
<script setup lang="ts">
</script>