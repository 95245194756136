<template>

  <div class="tr">
    <div class="th"  style="font-weight: bold">{{element.name}}</div>
  </div>

</template>

<script>


export default {
  components: {},
  props: {
    form:{
      type: Object,
    },
    data:{
      type:Object,
    },
    element:{
      type:Object
    },
    parent:{
      type:Object
    },
    type: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {}
  },
  created() {
  }
}

</script>

<style scoped lang="scss">

</style>