<template>

  <el-cascader v-model="value" :options="options" :disabled="disabled" @change="(val)=>$emit('update:modelValue',val)" filterable />

</template>

<script>
import {administrativeRegion} from "@/assets/basicData";

export default {
  props: {
    modelValue: {
      type: [String, Array],
      default: () => ''
    },
    level: {
      type: Number,
      default: () => 3
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  watch: {
    modelValue: {
      handler(newValue, oldValue) {
        this.value = newValue;
      },
      immediate: true
    }
  },
  data() {
    return {
      value: [],
      options: []
    }
  },
  created() {

    if (this.level === 1) {
      this.options = administrativeRegion.map(mapper => {
        return {
          "id": mapper.id,
          "label": mapper.name,
          "value": mapper.name,
          leaf: this.level <= 1,
        }
      })
    }
    else if (this.level === 2) {
      this.options = administrativeRegion.map(province => {
        return {
          "id": province.id,
          "label": province.name,
          "value": province.name,
          leaf: false,
          children: province.city.map(mapper => {
            return {
              "id": mapper.id,
              "label": mapper.name,
              "value": mapper.name,
              leaf: true,
            }
          })
        }
      })
    } else if (this.level === 3) {
      this.options = administrativeRegion.map(province => {
        return {
          "id": province.id,
          "label": province.name,
          "value": province.name,
          leaf: false,
          children: province.city.map(city => {
            return {
              "id": city.id,
              "label": city.name,
              "value": city.name,
              leaf: false,
              children: city.area.map(county => {
                return {
                  "id": county.id,
                  "label": county.name,
                  "value": county.name,
                  leaf: true,
                }
              })
            }
          })
        }
      })
    }

  },
  methods: {}

}
</script>

<style scoped lang="scss">

</style>