<template>

  <el-form label-width="80px" label-position="left">
    <el-form-item label="应用">
      <el-select v-model="attrs.app" @change="handleAppChange" style="width: 250px">
        <el-option v-for="(item,index) in apps" :key="index" :label="item.name" :value="item.id"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="表单">
      <el-select v-model="attrs.form" default-first-option filterable @change="handleFormChange" style="width: 250px">
        <el-option v-for="(item,index) in forms" :key="index" :label="item.name" :value="item.id"></el-option>
      </el-select>
    </el-form-item>
  </el-form>

  <el-divider></el-divider>


<!--  <div style="color: #2B313B">操作</div>-->

<!--  <el-radio-group style="margin: 20px 0" v-model="attrs.mode">-->
<!--    <el-radio label="修改" value="UPDATE"></el-radio>-->
<!--    <el-radio label="新增" value="ADD"></el-radio>-->
<!--  </el-radio-group>-->

  <div style="color: #2B313B" v-if="attrs.mode==='UPDATE'">
    主键设置
  </div>

  <el-select style="margin: 20px 0;" v-model="attrs.primaryKey" v-if="attrs.mode==='UPDATE'">
    <el-option v-for="(column,index) in columns" :key="index" :label="column.name" :value="column.name"></el-option>
  </el-select>

  <div style="color: #2B313B">
    填充设置
    <el-button icon="Plus" text style="float: right;" type="primary"
               @click="attrs.mappers.push({from:'',to:''})"></el-button>
  </div>

  <ul class="list">
    <li v-for="(item,index) in attrs.mappers" :key="index">
      <el-select v-model="item.from">
        <el-option v-for="(item,index) in columns" :key="index" :label="item.name" :value="item.name"></el-option>
      </el-select>
      <span class="describe">填充到</span>
      <el-select v-model="item.to">
        <el-option v-for="(item,index) in elements" :key="index" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <el-button icon="Remove" type="danger" link style="margin-left: 10px"
                 @click="attrs.mappers.splice(index,1)"></el-button>
    </li>
  </ul>

  <fill-setting ref="fillSetting"></fill-setting>

</template>

<script>

import {mapStores} from "pinia";
import {useEtlStore} from "@/stores/etl";
import fillSetting from "@/components/etl/node/fill/fillSetting.vue";

export default {
  computed: {
    ...mapStores(useEtlStore)
  },
  components: {
    fillSetting
  },
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      attrs: {},
      apps: [],
      forms: [],
      columns: [],
      elements: [],
    }
  },
  async created() {
    this.attrs = this.element.attrs;
    this.initColumns()
    this.queryApps();

    if (this.attrs.app) {
      this.queryForms(this.attrs.app)
    }

    if (this.attrs.form) {
      await this.queryElements(this.attrs.form)
    }
  },
  methods: {
    initColumns() {
      this.etlStore.getNodeColumns(this.element.id, this.data).then(res => {
        if (res.code === 200) {
          this.columns = res.data.map(mapper => {
            return {
              name: mapper.name,
              nodeName: mapper.nodeName,
              nodeType: mapper.nodeType
            }
          });
        }
      })
    },
    queryApps() {
      this.etlStore.listApp().then(res => {
        this.apps = res.data;
      })
    },
    handleAppChange(val) {
      this.attrs.app = val;
      this.attrs.form = '';
      this.attrs.keepColumns = [];
      this.queryForms(val)
    },
    queryForms(val) {
      this.etlStore.listForm(val).then(res => {
        this.forms = res.data;
      })
    },
    handleFormChange(val) {
      this.attrs.form = val;
      this.attrs.mappers = [];
      this.queryElements(val);
    },
    async queryElements(val) {
      await this.etlStore.formElements(val).then(res => {
        this.elements = this.etlStore.columns(res.data);
      })
    },
  }


}

</script>

<style scoped lang="scss">
.list {
  padding: 0;
  margin: 20px 0 0 0;

  li {
    list-style-type: none;
    line-height: 32px;
    color: #747677;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
}


.el-select {
  width: 150px;
}

.describe {
  margin: 0 10px;
}
</style>