<template>

  <el-button @click="$refs.buildSetting.call(element,data)" icon="Filter" link type="primary" size="small">模型设置
  </el-button>

  <el-form label-width="80px" label-position="top" style="margin-top: 20px;--el-text-color-regular:black" size="small">
    <el-form-item label="维度" v-if="element.attrs.dimensions.length>0">
      <template #default>
        <el-tag v-for="(item,index) in element.attrs.dimensions" effect="plain" :key="index">
          {{ element.attrs.aliases[item] || item }}
        </el-tag>
      </template>
    </el-form-item>

    <el-form-item label="指标" v-if="element.attrs.calculates.length>0">
      <template #default>
        <el-tag v-for="(item,index) in element.attrs.calculates" effect="plain" :key="index">
          {{ element.attrs.aliases[item] || item }}
        </el-tag>
      </template>
    </el-form-item>

  </el-form>


  <build-setting ref="buildSetting"></build-setting>

</template>

<script>
import buildSetting from "@/components/etl/node/build/buildSetting.vue";

export default {
  components: {
    buildSetting
  },
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    }
  },


}

</script>

<style scoped lang="scss">
.el-tag {
  margin: 4px;
}
</style>