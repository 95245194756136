<template>

  <el-dialog v-model="dialogVisible" title="过滤" :close-on-click-modal="false" width="920px"
             style="border-radius: 10px"
             append-to-body>
    <div v-if="node">
      <el-tag size="small" effect="plain">维度一般指从【某种角度】出发的度量统计，角度即为维度</el-tag>
      <el-tag size="small" effect="plain">指标一般指可以【量化的数据】，是统计后的结果</el-tag>
      <el-divider></el-divider>
      <el-table size="small" :height="isFullscreen?'70vh':'40vh'" border
                :data="columns.filter(filter=>keyword?(filter.name.indexOf(keyword)>=0):true)" :stripe="true">
        <el-table-column label="" prop="name" align="center">
          <template #header>
            <el-input v-model="keyword" size="small" placeholder="输入关键字搜索"/>
          </template>
        </el-table-column>
        <el-table-column label="输入源" align="center">
          <template #default="scope">
            {{ scope.row.nodeName }} ({{ scope.row.nodeType }})
          </template>
        </el-table-column>
        <el-table-column label="别名" align="center">
          <template #default="scope">
            <el-input v-model="node.attrs.aliases[scope.row.name]" size="small" style="width: 80%;"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="" align="center" width="150px">
          <template #default="scope">
            <el-checkbox :model-value="node.attrs.dimensions.indexOf(scope.row.name)>=0"
                         @change="handleClickDimetion(scope.row.name)">维度
            </el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="" align="left" width="150px">
          <template #default="scope">
            <el-checkbox :model-value="node.attrs.calculates.indexOf(scope.row.name)>=0"
                         @change="handleClickCalculate(scope.row.name)">指标
            </el-checkbox>
          </template>
        </el-table-column>
      </el-table>


    </div>
  </el-dialog>

</template>

<script>

import {mapStores} from "pinia";
import {useEtlStore} from "@/stores/etl";

export default {
  computed: {
    ...mapStores(useEtlStore)
  },
  props: {
    isFullscreen: {
      type: Boolean,
      default: () => false,
    }
  },
  data() {
    return {
      dialogVisible: false,
      node: null,
      data: null,
      columns: [],
      keyword: '',
    }
  },
  methods: {
    call(node, data) {
      this.node = node;
      this.data = data;
      this.dialogVisible = true;
      this.$nextTick(() => this.initColumns())
    },
    initColumns() {
      this.etlStore.getNodeColumns(this.node.id, this.data).then(res => {
        if (res.code === 200) {
          this.columns = res.data.map(mapper => {
            return {
              name: mapper.name,
              nodeName: mapper.nodeName,
              nodeType: mapper.nodeType
            }
          });
        }
      })
    },
    handleClickCalculate(calculate) {
      let index = this.node.attrs.calculates.indexOf(calculate);
      if (index >= 0) {
        this.node.attrs.calculates.splice(index, 1)
      } else {
        this.node.attrs.calculates.push(calculate)
        if (this.node.attrs.dimensions.indexOf(calculate) >= 0) {
          this.node.attrs.dimensions.splice(this.node.attrs.dimensions.indexOf(calculate), 1)
        }
      }

    },
    handleClickDimetion(dimention) {
      let index = this.node.attrs.dimensions.indexOf(dimention);
      if (index >= 0) {
        this.node.attrs.dimensions.splice(index, 1)
      } else {
        this.node.attrs.dimensions.push(dimention)
        if (this.node.attrs.calculates.indexOf(dimention) >= 0) {
          this.node.attrs.calculates.splice(this.node.attrs.calculates.indexOf(dimention), 1)
        }
      }

    }
  }
}
</script>

<style scoped lang="scss">
:deep(.el-tag) {
  margin: 4px;
}

:deep(.el-table ){
  padding: 10px;
}

:deep(.el-table thead) {
  color: #1F1C2C;
}

</style>
